import {NodeSetupInfo} from "../../../NodeSetupInfo";
import {Node} from "../../../../backend/Node";
import {v4} from "uuid";
import {GaugeDisplay} from "./NodeGauge";
import {triton} from "../../../../../triton/Triton";

export const NodeFluidTank: NodeSetupInfo = {
    label: "NodeFluidTank",
    classname: "sim.fluid-tank",
    parameterConfig: [],
    factory: parameters => {
        const capacity = Number(parameters.get("capacity") ?? 1e5);
        const levelPercentage = Number(parameters.get("levelPercentage") ?? 1e2);

        return new Node({
            id: v4(),
            classname: "sim.fluid-tank",
            label: "tank",
            defInPins: ["#", "±"],
            defOutPins: ["#", "l", "l%"],
            state: {
                level: capacity * (levelPercentage / 1e2),
                capacity: capacity,
                // visual config
                enableDisplay: false,
                // debug config
                debugSkipLevelAdjust: false
            },
            init: function () {

                const outputStats = () => {
                    const l = this.state.state.level;
                    const cap = this.state.state.capacity;

                    // Output current level
                    this.pins.out("l").write(l);
                    this.pins.out("l%").write((l / cap) * 1e2);
                }

                // Adjust tank level by provided value 'by'.
                this.pins.in("±").attachOnRead(by => {
                    // Numeric safeguard
                    by = Number(by ?? 0);

                    if (this.state.state.debugSkipLevelAdjust) return; // FIXME: prevents outputStats();

                    this.state.update(prevState => ({
                        level: Math.max(0, prevState.level + by)
                    }));

                    outputStats();
                });
            },
            customRenderer: node => {
                const s = node.state.state;

                const isDisplayEnabled = s.enableDisplay ?? false;
                if (!isDisplayEnabled) return undefined;

                const levelPercentage = s.level / s.capacity * 100;

                return (
                    <div style={{
                        // margin: "0 -8px",
                        padding: "8px 0"
                    }}>
                        <GaugeDisplay
                            actual={levelPercentage}
                            visuals={{
                                showThresholdMarkers: false,
                                showMarkedAreas: true,
                                showValue: true,
                                centralModuleWidth: 25 + 4,
                                height: 75
                            }}
                            modules={{
                                enableMarkerModule: false
                            }}
                            areas={[
                                {
                                    start: 0,
                                    end: 20,
                                    bevelMode: "bottom",
                                    withinAreaColor: "crimson",
                                    outsideAreaColor: "#21262d",
                                    border: false
                                }
                            ]}
                        />
                    </div>
                );
            }
        });;
    }
}
