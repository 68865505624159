import React, {CSSProperties, FC} from "react";
import styled from "styled-components";
import {Pin} from "../backend/Pin";
import {useTriton} from "../../triton/TritonHooks";
import {Triton} from "../../triton/Triton";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {IconButton} from "../../ardai/components/IconButton";
import {OutletRounded} from "@mui/icons-material";
import {SxProps} from "@mui/material";
import {FlatIconButton} from "../../ardai/components/FlatIconButton";
import {PinConnectionInfoDisplay} from "./PinConnectionInfoDisplay";
import {MenuGroup} from "../../ardai/components/MenuGroup";
import {TBPinDetails} from "./nodes/tb/NodeTBAdapter";
import {Tag} from "../../ardai/components/Tag";
import {TabHeader} from "../../triton/components/navigation/tabs/TabHeader";
import ReactJson from "react-json-view";
import {SDPromptField} from "../../imageSorter/sdInterface/SDPromptField";
import {px} from "../../base/logic/style/DimensionalMeasured";

//noinspection CssUnresolvedCustomProperty
const StyledPinInfoDisplay = styled.div<{
    t: Triton
}>`
    max-width: 300px;

    direction: initial;
    --padding: 8px;
    position: relative;
    display: grid;
    grid-template-rows: min-content auto min-content;
    border-radius: 8px;
    background-color: ${p => p.t.col("bg_modal")};


    // padding-right: 8px;

    .pin-info-header {
        padding: var(--padding);
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .pin-info-main {
        padding: 0 var(--padding);
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .pin-info-footer {
        padding: var(--padding);
        display: flex;
        gap: 4px;
        align-items: center;
    }
`;

export type PinInfoDisplayProps = {
    pin: Pin
}

export const PinInfoDisplay: FC<PinInfoDisplayProps> = props => {
    const t = useTriton();
    const pin = props.pin;
    const dye = pin.config.dye;

    const pinIconStyle: SxProps & CSSProperties = {
        fontSize: 14,
        color: dye === undefined ? "unset" : dye
    }

    return (
        <StyledPinInfoDisplay t={t} onClick={e => e.stopPropagation()}>
            <div className={"pin-info-header"}>

                <FlatIconButton children={
                    <OutletRounded sx={pinIconStyle}/>
                }/>

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    flexWrap: "wrap",
                    flexDirection: "row"
                }}>
                    {
                        ["inline"].map(keyword => (
                            <DescriptiveTypography
                                key={keyword}
                                text={keyword}
                                noSelect
                                style={{
                                    color: t.col("color_primary")
                                }}
                            />
                        ))
                    }


                    <DescriptiveTypography
                        text={`${pin.config.label}`}
                        noSelect
                        style={{ whiteSpace: "nowrap" }}
                    />

                    {/*
                    <DescriptiveTypography
                        text={":"}
                        noSelect
                        style={{
                            color: t.col("fg_muted")
                        }}
                    />
                    <Tag
                        tag={"any"}
                        // mainColor={t.colObj("color_secondary")}
                    />
                    */}
                </div>
            </div>

            <div className={"pin-info-main"}>

                {/*
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                    gap: 8,
                    padding: "0 8px"
                }}>
                    <TabHeader
                        title={"info"}
                        isActive={true}
                    />
                    <TabHeader
                        title={"connections"}
                        isActive={false}
                    />
                    <TabHeader
                        title={"handlers"}
                        isActive={false}
                    />
                </div>
                */}

                <TBPinDetails pin={pin}/>

                { false && pin.inputConnections.length > 0 && (
                    <div className={"connections"}>
                        <MenuGroup title={"Input connections"}>
                            { pin.inputConnections.map(destPin => (
                                <PinConnectionInfoDisplay
                                    fromPin={pin}
                                    destPin={destPin}
                                    connectionDirectionContext={"out"}
                                />
                            )) }
                        </MenuGroup>
                    </div>
                ) }



                { false && pin.outputConnections.length > 0 && (
                    <div className={"connections"}>
                        <MenuGroup title={"Output connections"}>
                            { pin.outputConnections.map(destPin => (
                                <PinConnectionInfoDisplay
                                    fromPin={pin}
                                    destPin={destPin}
                                    connectionDirectionContext={"out"}
                                />
                            )) }
                        </MenuGroup>
                    </div>
                ) }

                <div style={{
                    overflowX: "hidden",
                    width: 274
                }}>
                    <MenuGroup title={"Listeners"} showIndentationLine indentation={8} children={
                        pin.listeners.map((listener, idx) => (
                            <div key={idx}>
                                <MenuGroup title={`${idx}`} children={
                                    <SDPromptField
                                        h={px(200)}
                                        fontSize={12}
                                        value={(() => {
                                            let src = listener.read.toString();
                                            let srcLines = src.split("\n");
                                            const indentationProbeLine = srcLines[srcLines.length - 1];
                                            const indentation = indentationProbeLine.length - indentationProbeLine.trimStart().length;
                                            for (let i = 1; i < srcLines.length; i++) {
                                                srcLines[i] = srcLines[i].slice(indentation)
                                            }
                                            return srcLines.join("\n");
                                        })()}
                                    />
                                }/>
                            </div>
                        ))
                    }/>
                </div>


                <MenuGroup title={"Value"}>
                    { pin.value !== undefined && (
                        <ReactJson src={pin.value} collapsed theme={"grayscale"}/>
                    ) || <DescriptiveTypography text={"undefined"}/>}
                </MenuGroup>


            </div>

            <div className={"pin-info-footer"}>
                {/*
                <MenuGroup title={"Debugging"}>
                    <DescriptiveTypography noSelect text={`${pin.config.id}`} style={{ whiteSpace: "nowrap" }}/>
                </MenuGroup>
                */}
            </div>
        </StyledPinInfoDisplay>
    );
}
