import {FC} from "react";
import {PinBank} from "../backend/PinBank";
import {NodePinDisplay} from "./NodePinDisplay";
import styled from "styled-components";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {Alignment} from "./Alignment";

const StyledPinBankDisplay = styled.div<{
    p: PinBankDisplayProps
}>`
  border-radius: ${p => p.p.align === Alignment.RIGHT ? `8px 0 0 8px` : `0 8px 8px 0`};
    
  padding: 8px 4px 8px 0;
  background-color: #10141a;
  margin-top: 8px;
  display: grid;
  grid-template-columns: min-content min-content;
  direction: ${p => (p.p.align ?? Alignment.LEFT) === Alignment.LEFT ? "ltr" : "rtl"};
  
  .pins {
    display: flex;
    flex-direction: column;
    // left: -5px;
    position: relative;
    
    // left: ${p => (p.p.align ?? Alignment.LEFT) === Alignment.LEFT ? "-5px" : "unset"};
    right: ${p => (p.p.align ?? Alignment.LEFT) === Alignment.RIGHT ? "-5px" : "unset"};
  }
  
  .bank-descriptor {
    margin-left: 4px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .bank-label {
      // transform: rotate(90deg);
      // box-sizing: border-box;
      // display: block;
    }
  }
`;

export type PinBankDisplayProps = {
    bank: PinBank,
    align?: Alignment
}

export const PinBankDisplay: FC<PinBankDisplayProps> = props => {
    const align: Alignment = props.align ?? Alignment.LEFT;

    return (
        <StyledPinBankDisplay p={props}>
            <div className={"pins"}>
                {
                    Array.from(props.bank.pins).map(([key, pin], idx) => {
                        return (
                            <NodePinDisplay
                                key={key}
                                pin={pin}
                                position={align === Alignment.LEFT ? "left" : "right"} // TODO: pass alignment directly
                            />
                        )
                    })
                }
            </div>

            { !props.bank.config.hideLabelFlag && (
                <div className={"bank-descriptor"}>
                    <div className={"bank-label"}>
                        <DescriptiveTypography
                            noSelect
                            text={`${props.bank.config.label}`}
                            style={{
                                fontSize: 10,
                                writingMode: "vertical-lr"
                            }}
                        />
                    </div>
                </div>
            ) }


        </StyledPinBankDisplay>
    );
}
