export type PinInputTransformer<T> = (input: any) => T

/**
 * Default input pin transformers
 */
export const defaultsPinInputTransformers = {

    /**
     * Converts input to a number
     */
    toNumber: <PinInputTransformer<number>>(input => Number(input)),

    /**
     * Converts input to a boolean
     */
    toBoolean: <PinInputTransformer<boolean>>(input => Boolean(input))
}