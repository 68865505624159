import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";
import {Pin} from "../../../backend/Pin";
import {NodeImageBufferClass} from "./NodeImageBuffer";

export let NodeImageInverter: NodeSetupInfo = {
    label: "inverter",
    classname: "image.pipeline.inverter",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "image.pipeline.inverter",
        label: "inverter",
        defOutPins: [DefaultCharacterSymbols.clockPinKey],
        defInPins: [DefaultCharacterSymbols.clockPinKey, "#buf"],
        init: function () {
            this.createInternalWire({
                targetInPin: DefaultCharacterSymbols.clockPinKey,
                targetOutPin: DefaultCharacterSymbols.clockPinKey,
                transformer: clockSignal => {
                    const bufInstancePin: Pin<any, NodeImageBufferClass> = this.pins.in("#buf").inputConnections[0]
                    const bufferNode = bufInstancePin.value!;
                    const imageData = bufferNode.raw.value!;
                    const buf = imageData.data;

                    const max = 255;
                    for (let y = 0; y != imageData.height; y++) {
                        for (let x = 0; x != imageData.width; x++) {
                            let i = (y * imageData.width + x) * 4;
                            buf[i    ] = max - buf[i    ];
                            buf[i + 1] = max - buf[i + 1];
                            buf[i + 2] = max - buf[i + 2];
                        }
                    }

                    return clockSignal;
                }
            })
        }
    })
}
