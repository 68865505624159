import {NodeSetupInfo} from "../../../NodeSetupInfo";
import {Node} from "../../../../backend/Node";
import {v4} from "uuid";
import {DefaultCharacterSymbols} from "../../../DefaultCharacterSymbols";
import {FC, ReactNode, useEffect, useRef} from "react";
import {cubicBezier, easeInOut, transform} from "framer-motion";
import {IndicatorContainer} from "../../../components/indicators/IndicatorContainer";
import {GaugeDisplay} from "./NodeGauge";
import {IndicatorIcon} from "../../../components/indicators/IndicatorIcon";
import {IndicatorUtils} from "../../../components/indicators/utils/IndicatorUtils";
import {
    AirRounded, CableRounded, FireplaceRounded,
    GasMeterRounded, HeatPumpRounded, LocalFireDepartmentRounded,
    LocalGasStationRounded,
    PercentRounded, PlayCircleFilledWhiteRounded, ThermostatRounded,
    VerticalAlignTopRounded
} from "@mui/icons-material";
import {Interpolate} from "../../../../../triton/components/advanced/Interpolate";
import {DescriptiveTypography} from "../../../../../triton/components/typography/DescriptiveTypography";
import {Tag} from "../../../../../ardai/components/Tag";
import {IndicatorFusePanel} from "../../../components/indicators/IndicatorFusePanel";
import {IIndicatorPanelContext, IndicatorPanel} from "./utils/IndicatorPanel";

/**
 * @return new current value
 */
const calcNumericalApproach = (
    current: number,
    target: number,
    changeRate: ((dist: number) => number) | number = 1
): number => {
    if (current === target) return current;
    const dist = Math.abs(current - target);
    const rate = typeof changeRate === "function" ? changeRate(dist) : changeRate;
    if (current < target) {
        // increase current to meet target
        return Math.min(target, current + rate);
    } else {
        // decrease current to meet target
        return Math.max(target, current - rate);
    }
}

export type CombustionEngineState = {
    // fuel configuration
    fuelInjectedInCurrentCycle: number,
    level: number,
    capacity: number,

    // environment (temperature)
    temperature: number,

    // state
    airCompressionFactor: number,
    thrust: number,
    rpm: number,
    isIgnitionActive: boolean,
    isCombusting: boolean,

    // fuses
    masterFuse: boolean,
    overheatFuse: boolean
}

export type NodeCombustionEngineState = CombustionEngineState & {
    isInDebugMode: false
};

export const NodeCombustionEngine: NodeSetupInfo = {
    label: "NodeCombustionEngine",
    classname: "sim.combustion-engine",
    parameterConfig: [],
    factory: parameters => new Node<NodeCombustionEngineState>({
        id: v4(),
        classname: "sim.combustion-engine",
        label: "eng",
        defInPins: [
            DefaultCharacterSymbols.clockPinKey,
            "#",
            "±",
            "e"
        ],
        defOutPins: [
            DefaultCharacterSymbols.clockPinKey,
            "#",
            "cM" // cooler mode
        ],
        state: {
            // fuel configuration
            fuelInjectedInCurrentCycle: 0,
            level: 0,
            capacity: 0,
            // environment (temperature)
            temperature: 0,
            // state
            thrust: 0,
            rpm: 0,
            airCompressionFactor: 1,
            isIgnitionActive: false,
            isCombusting: false,
            // fuses
            masterFuse: true,
            overheatFuse: true,
            // node-internal data
            isInDebugMode: false
        },
        init: function () {

            // TODO: Add description
            this.pins.in("e").attachOnRead(isIgnitionActive => {
                isIgnitionActive = Boolean(isIgnitionActive ?? false);
                this.state.update(prevState => ({
                    isIgnitionActive
                }));
            });

            // TODO: Add description
            this.pins.in("±").attachOnRead(by => {
                // Numeric safeguard
                by = Number(by ?? 0);
                this.state.update(prevState => ({
                    level: Math.max(0, prevState.level + by)
                }));
            });

            this.pins.in(DefaultCharacterSymbols.clockPinKey).attachOnRead(sig => {
                const state = this.state;
                const cur = state.state;

                const coolerModeRequestPin = this.pins.out("cM");
                const requestCoolerMode = (mode: "off" | "normal" | "max") => {
                    let encodedMode: number = 0;
                    switch (mode) {
                        case "off":
                            encodedMode = 0;
                            break;
                        case "normal":
                            encodedMode = 1;
                            break;
                        case "max":
                            encodedMode = 2;
                            break;
                    }
                    coolerModeRequestPin.write(encodedMode);
                }

                const isAlreadyCombusting: boolean = cur.isCombusting;
                const isIgnitionActive: boolean = cur.isIgnitionActive;

                // noinspection UnnecessaryLocalVariableJS
                const isDebugging = cur.isInDebugMode;
                // noinspection UnnecessaryLocalVariableJS
                const infiniteFuel = isDebugging;
                if (infiniteFuel) {
                    cur.level = cur.capacity;
                }

                let masterFuse = cur.masterFuse;
                let overheatFuse = cur.overheatFuse;

                // Heat security reaction logic
                heatSecurityReactionLogic: {
                    const overheatingTemperatureThreshold = 1e3;
                    const currentTemperature = cur.temperature;

                    if (currentTemperature >= overheatingTemperatureThreshold) {
                        overheatFuse = false;
                        break heatSecurityReactionLogic;
                    }

                    const autoReconnectOnSaveTemperatureIsReached = true;
                    if (autoReconnectOnSaveTemperatureIsReached) {
                        const autoReconnectSaveTemperatureThreshold = 500;
                        if (currentTemperature <= autoReconnectSaveTemperatureThreshold) {
                            overheatFuse = true;
                        }
                    }
                }

                coolingAdjustmentLogic: {
                    const normalCoolingModeEnableThreshold = 400;
                    const normalCoolingModeDisableThreshold = 100;
                    const maxCoolingModeEnableThreshold = 700;
                    const currentTemperature = cur.temperature;

                    if (currentTemperature < normalCoolingModeDisableThreshold) {
                        requestCoolerMode("off");
                        break coolingAdjustmentLogic;
                    }

                    if (currentTemperature > maxCoolingModeEnableThreshold) {
                        requestCoolerMode("max");
                        break coolingAdjustmentLogic;
                    }

                    if (currentTemperature > normalCoolingModeEnableThreshold) {
                        requestCoolerMode("normal");
                        break coolingAdjustmentLogic;
                    }
                }

                /**
                 * Checks if all fuses necessary for the combustion process are connected
                 */
                const isCombustionFuseLineClosed = () => masterFuse && overheatFuse;

                // Calculate net thrust
                const fuelAmountInCombustion = cur.level;
                const minFuelForCombustion = 2;

                const shouldPerformCombustionCycle =
                    (fuelAmountInCombustion >= minFuelForCombustion) &&
                    (isAlreadyCombusting || isIgnitionActive) &&
                    isCombustionFuseLineClosed()
                ;

                // Calculate air intake parameters
                let environmentAirDensity = 1;
                let currentCompressorRPM = cur.rpm; // Expected range: [0, 10.000]
                const rpmToCompressionTransformer = transform([0, 10e3], [1, 2.5], {
                    clamp: true,
                    // ease: easeInOut
                });

                let airCompressionFactor = rpmToCompressionTransformer(currentCompressorRPM);
                let airDensityInCombustionChamber = environmentAirDensity * airCompressionFactor;

                // Perform combustion cycle (if combusting)
                let newThrust = 0;
                if (shouldPerformCombustionCycle) {
                    // Will perform combustion
                    const fuelEnergyDensity = 1;
                    const combustionEfficiencyFactor = 1;
                    newThrust = fuelAmountInCombustion * fuelEnergyDensity;
                    // Apply efficiency thrust factor
                    newThrust *= combustionEfficiencyFactor;

                    // Apply air density factor
                    newThrust *= airDensityInCombustionChamber
                } else {
                    // No combustion, fuel will be discarded
                    // noop
                }

                // Calculate next compressor rpm
                const environmentRPM = 0; // Engine RPM at idle thrust -> caused by environment air flow or external (non-combustion-system-based) system like a starting motor
                let newRPM = cur.rpm;
                newRPM = calcNumericalApproach(newRPM, environmentRPM, 2.5e2);
                const thrustToRPMTransformer = transform([0, 100], [0, 10e3], {
                    clamp: false,
                    // ease: easeInOut
                });
                let theoreticalRPMFromThrust = thrustToRPMTransformer(newThrust);
                newRPM = calcNumericalApproach(newRPM, theoreticalRPMFromThrust, 1e3);

                // Calculate heat changes (combustion & dissipation)
                const environmentTemperature = 20;
                const thrustToExpectedHeatTransformer = transform([0, 100], [0, 500], {
                    clamp: false,
                    // ease: easeInOut
                });
                const heatGeneratedByCombustion = thrustToExpectedHeatTransformer(newThrust);
                const naturalHeatDissipationAmount = 1e0;
                let newTemperature = cur.temperature;
                newTemperature = calcNumericalApproach(newTemperature, environmentTemperature, naturalHeatDissipationAmount);
                if (shouldPerformCombustionCycle && heatGeneratedByCombustion > newTemperature) {
                    newTemperature = calcNumericalApproach(newTemperature, heatGeneratedByCombustion, 1.5e1);
                }

                const fuelInjectedInCurrentCycle = cur.level;
                state.update({
                    fuelInjectedInCurrentCycle,
                    airCompressionFactor,
                    level: 0,
                    rpm: newRPM,
                    temperature: newTemperature,
                    thrust: newThrust,
                    isCombusting: shouldPerformCombustionCycle,
                    masterFuse,
                    overheatFuse
                });

                // Relay clock signal to all sub components
                this.pins.out(DefaultCharacterSymbols.clockPinKey).write(sig);
            });
        },
        customRenderer: node => (
            <NodeCombustionEngineDisplay
                node={node}
            />
        )
    })
}

const NodeCombustionEngineDisplay: FC<{
    node: Node<NodeCombustionEngineState>
}> = props => {
    const state = props.node.state;
    const cur = state.state;
    const compressorRPMPerThousand = cur.rpm === 0 ? 0 : (cur.rpm / 1e3);

    const updateMaxFuelIntake = (by: number, delta: boolean = false) => {
        state.update(prevState => ({
            capacity: Math.max(delta ? (prevState.capacity + by) : by, 0)
        }))
    }

    const indicatorPanelCtxRef = useRef<IIndicatorPanelContext | null>(null);

    useEffect(() => {
        const ipc = indicatorPanelCtxRef.current;
        if (ipc === null) return;
        const n = props.node;
        const s = n.state.state;

        if (s.isIgnitionActive) ipc.add("isIgnitionActive", "ignition")
        else ipc.delete("isIgnitionActive")

        if (s.fuelInjectedInCurrentCycle < s.capacity) ipc.add("isFuelStarving", "starving")
        else ipc.delete("isFuelStarving")

        const tempLevel1WarningThreshold = 700;
        if (s.temperature >= tempLevel1WarningThreshold) ipc.add("tempLevel1Warning", "heat I")
        else ipc.delete("tempLevel1Warning")

        const tempLevel2WarningThreshold = 900;
        if (s.temperature >= tempLevel2WarningThreshold) ipc.add("tempLevel2Warning", "heat II")
        else ipc.delete("tempLevel2Warning")

        if (!s.overheatFuse) ipc.add("overheating", "overheat")
        else ipc.delete("overheating")
    }, [state]);

    return (
        <div style={{
            padding: "8px 0",
            display: "flex",
            flexDirection: "row",
            gap: 6 // -> becomes a visible gap of 4px, cause containers have 1px box-shadows each
        }}>

            <div style={{
                // padding: "8px 0",
                display: "flex",
                flexDirection: "column",
                gap: 6 // -> becomes a visible gap of 4px, cause containers have 1px box-shadows each
            }}>
                <IndicatorContainer compact>
                    <GaugeDisplay
                        actual={cur.thrust}
                        visuals={{
                            showMarkedAreas: false,
                            showThresholdMarkers: false,
                            showValue: true,
                            centralModuleWidth: 25,
                            height: 75
                        }}
                        modules={{
                            enableMarkerModule: false
                        }}
                    />
                    <GaugeDisplay
                        actual={compressorRPMPerThousand * 10}
                        visuals={{
                            showThresholdMarkers: false,
                            showMarkedAreas: true,
                            showValue: true,
                            centralModuleWidth: 25,
                            height: 75
                        }}
                        modules={{
                            enableMarkerModule: false
                        }}
                        areas={[
                            {
                                start: 75,
                                end: Number.MAX_VALUE,
                                bevelMode: "top",
                                withinAreaColor: "#ffdf60",
                                outsideAreaColor: "#21262d",
                                border: false
                            }
                        ]}
                    />
                </IndicatorContainer>

                <IndicatorContainer>

                    {/* actual volume transfer */}
                    <IndicatorIcon
                        mappingKey={cur.temperature}
                        colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                            .gEq(1e3, "crimson")
                            .gEq(7.5e2, "#ffdf60")
                            .fallback(0, "rgb(33, 38, 45)")
                        )}
                        iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                            .fallback(0, <ThermostatRounded/>)
                        )}
                    />
                    <Interpolate value={cur.temperature} children={d => (
                        <DescriptiveTypography text={d} style={{
                            fontSize: 12,
                            textAlign: "end"
                        }}/>
                    )}/>

                    <IndicatorIcon
                        mappingKey={cur.fuelInjectedInCurrentCycle}
                        colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                            // Error indicator if no fuel is delivered while some is requested
                            .mappingFn(key => key === 0 && cur.capacity > 0)(0, "crimson")
                            .mappingFn(key => key < cur.capacity)(0, "#ffdf60")

                            // .mappingFn(key => !cur.isCombusting && key > 0)(0, "#ffdf60")
                            .fallback(0, "rgb(33, 38, 45)")
                        )}
                        iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                            .fallback(0, <LocalGasStationRounded/>)
                        )}
                    />
                    <Interpolate value={cur.fuelInjectedInCurrentCycle} children={d => (
                        <DescriptiveTypography text={d} style={{
                            fontSize: 12,
                            textAlign: "end"
                        }}/>
                    )}/>

                    <IndicatorIcon
                        mappingKey={cur.capacity}
                        colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                            .fallback(0, "rgb(33, 38, 45)")
                        )}
                        iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                            .fallback(0, <LocalGasStationRounded/>)
                        )}
                    />
                    <Interpolate value={cur.capacity} children={d => (
                        <DescriptiveTypography text={d} style={{
                            fontSize: 12,
                            textAlign: "end"
                        }}/>
                    )}/>

                    <IndicatorIcon
                        mappingKey={cur.isCombusting ? 1 : 0}
                        colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                            .eq(1, "#60ffc7")
                            .fallback(0, "rgb(33, 38, 45)")
                        )}
                        iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                            .fallback(0, <LocalFireDepartmentRounded/>)
                        )}
                    />
                    <IndicatorIcon
                        mappingKey={cur.isIgnitionActive ? 1 : 0}
                        colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                            .eq(1, "#ffdf60")
                            .fallback(0, "rgb(33, 38, 45)")
                        )}
                        iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                            .fallback(0, <PlayCircleFilledWhiteRounded/>)
                        )}
                        onClick={() => {
                            state.reverseBool("isIgnitionActive")
                        }}
                    />
                </IndicatorContainer>

                <IndicatorFusePanel fuses={[
                    {
                        icon: <CableRounded/>,
                        connected: cur.masterFuse,
                        label: "Master fuse",
                        onClick: () => {
                            state.update(prevState => ({
                                masterFuse: !prevState.masterFuse
                            }))
                        }
                    },
                    {
                        icon: <LocalFireDepartmentRounded/>,
                        connected: cur.overheatFuse,
                        label: "Overheat fuse",
                        onClick: () => {
                            state.update(prevState => ({
                                overheatFuse: !prevState.overheatFuse
                            }))
                        }
                    }
                ]}/>

                <IndicatorContainer>
                    <Tag tag={"-"} onClick={e => updateMaxFuelIntake(-(e.altKey ? 10 : 1), true)}/>
                    <Tag tag={"+"} onClick={e => updateMaxFuelIntake(e.altKey ? 10 : 1, true)}/>

                    <Tag tag={"0"} onClick={() => updateMaxFuelIntake(0)}/>
                    <Tag tag={
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                            width: "100%",
                            height: "100%"
                        }}>
                            <VerticalAlignTopRounded sx={{
                                fontSize: 14,
                                position: "absolute"
                            }}/>
                        </div>
                    } onClick={() => updateMaxFuelIntake(100)}/>
                </IndicatorContainer>

                <IndicatorPanel onInit={ctx => {
                    indicatorPanelCtxRef.current = ctx;
                }}/>
            </div>

            {/*
            <div style={{
                width: 100,
                // padding: "8px 0",
                display: "grid",
                gridTemplateRows: "auto",
                gap: 6 // -> becomes a visible gap of 4px, cause containers have 1px box-shadows each
            }}>
                <IndicatorContainer columns={1}>
                    <div style={{
                        height: "100%"
                    }}>
                        <IndicatorPanel onInit={ctx => {
                            indicatorPanelCtxRef.current = ctx;
                        }}/>
                    </div>
                </IndicatorContainer>
            </div>
            */}



        </div>
    );
}
