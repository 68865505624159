import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const NodeObjectToJSON: NodeSetupInfo = {
    label: "NodeObjectToJSON",
    classname: "objects.object-to-json",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "objects.object-to-json",
        label: "to json",
        defOutPins: ["s"],
        defInPins: ["o"],
        state: {
            enablePrettyPrint: false
        },
        init: function () {
            this.createInternalWire({
                targetInPin: "o",
                targetOutPin: "s",
                transformer: s => {
                    const enablePrettyPrint = this.state.state.enablePrettyPrint ?? false;
                    const space = enablePrettyPrint ? 2 : 0;
                    return JSON.stringify(s, null, space);
                }
            })
        }
    })
}
