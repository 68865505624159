import {Node} from "../../../backend/Node";
import {Pixel} from "./data/Pixel";
import {Std} from "../../../../../Std";
import {Pin} from "../../../backend/Pin";
import also = Std.also;

export type NodeImageBufferState<PixelType> = {
    width: number,
    height: number,
    buffer: PixelType[][] // <rows><cols>
}

export const NodeImageBuffer = {
    label: "NodeImageBuffer",
    classname: "images.buffer",
    parameterConfig: [],
    factory: () => new NodeImageBufferClass
}

/**
 * Node contract:
 * { w, h: number } -> { # }
 */

export class NodeImageBufferClass<PixelType = Pixel> extends Node<NodeImageBufferState<PixelType>> {

    private readonly canvas: HTMLCanvasElement = document.createElement("canvas");

    private readonly img: HTMLImageElement = document.createElement("img");

    private readonly _ctx2d?: CanvasRenderingContext2D;

    b64Out = this.pins.out("b64");

    this = this.pins.out("#").value = this;

    raw: Pin<any, ImageData> = also(this.pins.out("raw"), p => {
        p.setTypeName("js::ImageData");
    });

    b64In = this.pins.in("b64").attachOnRead(async base64 => {
        this.img.src = base64;
        await this.img.decode();
        this.canvas.width = this.img.naturalWidth;
        this.canvas.height = this.img.naturalHeight;
        this.ctx2d.drawImage(this.img, 0, 0);
        const surface = this.ctx2d.getImageData(0, 0, this.canvas.width, this.canvas.height);
        this.raw.value = surface;
        this.ctx2d.putImageData(surface, 0, 0);
        let url = this.canvas.toDataURL();
        this.b64Out.write(url);
    });

    push = this.pins.in("<-").attachOnRead(() => {
        const imageData = this.raw.value;
        if (imageData === undefined) throw Error("image data is null");
        this.ctx2d.putImageData(imageData, 0, 0);
        let url = this.canvas.toDataURL();
        this.b64Out.write(url);
    });

    constructor(width: number = 0, height: number = 0) {
        super({
            label: "img buf",
            state: {
                width,
                height,
                buffer: []
            }
        });

        this._ctx2d = this.canvas.getContext("2d", {
            willReadFrequently: true
        })!;
        if (this._ctx2d == undefined) throw new Error("canvas: 2d ctx is null");
    }

    public getPixel(x: number, y: number): PixelType {
        return this.getRow(y)[x];
    }

    public setPixel(x: number, y: number, pixel: PixelType) {
        this.getRow(y)[x] = pixel;
    }

    private getRow(y: number): PixelType[] {
        if (this.state.state.buffer[y] === undefined) {
            this.state.state.buffer[y] = [];
        }
        return this.state.state.buffer[y];
    }

    private get ctx2d(): CanvasRenderingContext2D {
        return this._ctx2d!;
    }
}
