import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export let Adder: NodeSetupInfo = {
    label: "adder",
    classname: "arithmetic.adder",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "arithmetic.adder",
        label: "add",
        defOutPins: ["y"],
        defInPins: ["a", "b"],
        init: function () {
            const calc = () => {
                const sum = this.pins.allDefaultIn
                    .map(pin => Number(pin.lastReadState))
                    .reduceRight((p, c) => p + c);

                this.pins.out("y").write(sum);
            }

            this.pins.allDefaultIn.map(pin => {
                pin.attach({
                    read(data: any) {
                        calc();
                    }
                })
            })
        }
    })
}

Adder = {
    label: "adder",
    classname: "arithmetic.adder",
    parameterConfig: [],
    factory: () => new NodeAdder
}

export class NodeAdder extends Node {

    a = this.pins.in("a").attachOnRead(() => this.calc());

    b = this.pins.in("b").attachOnRead(() => this.calc());

    y = this.pins.out("y");

    constructor() {
        super({
            classname: "arithmetic.adder",
            label: "add"
        });
    }

    calc() {
        this.y.write(Number(this.a.lastReadState) + Number(this.b.lastReadState));
    }
}

