import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {Triton, triton} from "../../../../triton/Triton";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {ButtonGroup} from "../../../../ardai/components/ButtonGroup";
import {Tag} from "../../../../ardai/components/Tag";
import {
    AutoGraphRounded, GpsNotFixedRounded, GpsOffRounded, HorizontalRuleRounded,
    LineAxisRounded, MouseRounded,
    Rotate90DegreesCwRounded,
    RotateRightRounded, RuleRounded,
    SettingsRounded, Straighten, StraightenRounded
} from "@mui/icons-material";
import {Pin} from "../../../backend/Pin";
import {Color} from "../../../../base/logic/style/Color";
import {RGBUtils} from "../../../../utils/color/RGBUtils";
import {FC, useState} from "react";
import {useTriton} from "../../../../triton/TritonHooks";
import {AnimatePresence} from "framer-motion";
import {motion} from "framer-motion";
import styled from "styled-components";

type Plot = {
    id: string,
    inReg: number,
    plot: Array<number>
}

type AdvancedPlotterState = {
    plots: Array<Plot>,
    scaleX: number,
    scaleY: number,
    showLine: boolean,
    advancedView: boolean,
    offsetY: number,
    enableYAxisLabeling: boolean,
    enableMouseAnnotations: boolean
}

export const AdvancedPlotter: NodeSetupInfo = {
    label: "",
    classname: "visualization.adv-plotter",
    parameterConfig: [],
    factory: parameters => new Node<AdvancedPlotterState>({
        id: v4(),
        classname: "visualization.adv-plotter",
        label: "adv plotter",
        defInPins: ["c"],
        state: {
            plots: [],
            scaleX: 1,
            scaleY: 1,
            showLine: true,
            advancedView: false,
            offsetY: 0,
            enableYAxisLabeling: true,
            enableMouseAnnotations: true
        },
        init: function () {
            const node = this;
            const plotHistoryLen = 250;

            this.pins.in("c").attach({
                read(clockPulse: any) {
                    node.state.update(prevState => {
                        prevState.plots.map(plot => {
                            const correspondingPin = node.pins.getPinById(plot.id);
                            if (!correspondingPin?.hasInputConnections) return;
                            plot.plot.unshift(plot.inReg);
                            plot.plot = plot.plot.slice(0, plotHistoryLen)
                        })
                        return {};
                    });
                }
            })

            let idx = 0;
            const lines = this.pins.createPinBank("lines", {
                label: "lines",
                hideLabelFlag: true
            });

            const newPlotInputLine = () => {
                const pin = lines.pin(`${idx++}`);
                const pinDye = Color.fromRGBValueCompound(RGBUtils.createRandomRGBValues(.5));
                pin.config.dye = pinDye.toHex("#", false);
                const plotId = pin.config.id!;

                node.state.update(prevState => {
                    prevState.plots.push({
                        id: plotId,
                        inReg: 0,
                        plot: []
                    });
                    return {};
                });
            }

            lines.attach({
                onPinNewInboundConnection(thisPin: Pin, fromPin: Pin) {
                    newPlotInputLine();
                },
                onPinRead(pin: Pin, mag: number) {
                    const plotId = pin.config.id;
                    node.state.update(prevState => {
                        const plot = prevState.plots.find(p => p.id === plotId)!;
                        plot.inReg = mag;
                        return {};
                    });
                },
                onPinCutInboundConnection(thisPin: Pin, fromPin: Pin) {
                    if (!thisPin.hasInputConnections) {
                        lines.removePinByLabel(thisPin.label);
                    }
                }
            });

            newPlotInputLine();
        },
        customRenderer: node => {
            return (
                <AdvancedPlotterDisplay
                    node={node}
                />
            )
        }
    })
}

// noinspection CssUnresolvedCustomProperty
const StyledPlot = styled.svg<{
    t: Triton
}>`
    &[data-soft-highlight=true] {
        --plot-soft-highlight-color: ${p => p.t.col("color_primary")};
        
        .plot-line {
            stroke: var(--plot-soft-highlight-color);
        }
        
        .plot-point {
            fill: var(--plot-soft-highlight-color);
        }
    }
`;

const AdvancedPlotterDisplay: FC<{
    node: Node<AdvancedPlotterState>
}> = props => {
    const { node } = props;

    const state = node.state.state;
    const plotHeight = 75;
    const t = useTriton();
    const defaultPointColor = t.col("color_secondary");
    const lineColor = t.col("fg_muted");

    return (
        <div style={{
            display: "grid",
            gap: 4,
            paddingBottom: 8
        }}>

            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                // gap: 4,
                padding: "8px 0",
                position: "relative",
            }}>
                { state.enableMouseAnnotations && (
                    <MouseFollower/>
                ) }

                { state.enableYAxisLabeling && (
                    <div style={{
                        padding: "8px 0",
                        height: "100%",
                        position: "absolute",
                        left: 0,
                        top: 0
                    }}>
                        <div style={{
                            height: "100%",
                            position: "relative",
                            width: 0,
                            borderRight: `1px dashed ${t.col("fg_muted")}`,
                            opacity: .5
                        }}>
                            <PlotterYAxisMarker
                                percentage={100}
                                value={plotHeight}
                                offset={state.offsetY}
                                scale={state.scaleY}
                            />
                            <PlotterYAxisMarker
                                percentage={50}
                                value={.5 * plotHeight}
                                offset={state.offsetY}
                                scale={state.scaleY}
                            />
                            <PlotterYAxisMarker
                                percentage={0}
                                value={0}
                                offset={state.offsetY}
                                scale={state.scaleY}
                            />
                        </div>
                    </div>
                ) || <span/> }



                <div style={{
                    width: "100%",
                    height: plotHeight,
                    position: "relative",
                    overflow: "hidden",
                }}>
                    {
                        node.state.state.plots.map(plot => {
                            const pointColor = node.pins.getPinById(plot.id)?.config.dye ?? defaultPointColor;
                            // data-pin-target={forPinId}
                            const forPinId = plot.id;

                            return (
                                <StyledPlot
                                    t={t}
                                    data-pin-target={forPinId}
                                    // width={100}
                                    height={plotHeight}
                                    style={{
                                        position: "absolute",
                                        // transform: "scale(1, -1)"
                                    }}
                                >
                                    { state.showLine && (
                                        <polyline
                                            className={"plot-line"}
                                            stroke={lineColor}
                                            strokeLinejoin={"round"}
                                            strokeWidth={1}
                                            points={
                                                plot.plot
                                                    .map((y, x) => `${x + (x * state.scaleX)},${(plotHeight) - ((y + state.offsetY) * state.scaleY)}`)
                                                    .join(" ")
                                            }
                                        />
                                    ) }

                                    { plot.plot.map((y, x) => {
                                        return (
                                            <circle
                                                className={"plot-point"}
                                                cx={x + (x * state.scaleX)}
                                                cy={(plotHeight) - ((y + state.offsetY) * state.scaleY)}
                                                r={1}
                                                fill={pointColor}
                                            />
                                        );
                                    }) }
                                </StyledPlot>
                            );
                        })
                    }
                </div>
            </div>



            <div style={{
                display: "flex",
                alignItems: "center",
                gap: 4
            }}>
                <ButtonGroup>
                    <Tag tag={"sY+"} onClick={e => {
                        e.stopPropagation();
                        node.state.update({
                            scaleY: state.scaleY + (e.ctrlKey ? .1 : 1)
                        });
                    }}/>
                    <Tag tag={"sY-"} onClick={e => {
                        e.stopPropagation();
                        node.state.update({
                            scaleY: state.scaleY - (e.ctrlKey ? .1 : 1)
                        })
                    }}/>
                </ButtonGroup>
                <ButtonGroup>
                    <Tag tag={"sX+"} onClick={e => {
                        e.stopPropagation();
                        node.state.update({
                            scaleX: state.scaleX + (e.ctrlKey ? .1 : 1)
                        })
                    }}/>
                    <Tag tag={"sX-"} onClick={e => {
                        e.stopPropagation();
                        node.state.update({
                            scaleX: state.scaleX - (e.ctrlKey ? .1 : 1)
                        })
                    }}/>
                </ButtonGroup>

                <Tag
                    active={state.advancedView}
                    applyActiveTextStyle
                    applyActiveScaling
                    onClick={() => node.state.reverseBool("advancedView")}
                    tag={ctx => (
                        <SettingsRounded sx={{
                            color: ctx.props.active ? ctx.t.col("color_primary") : undefined,
                            fontSize: 14
                        }}/>
                    )}
                />
            </div>

            <AnimatePresence initial={false} children={
                state.advancedView && (
                    <motion.div

                        children={
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 4
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    overflow: "hidden",
                                    gap: 4
                                }}>
                                    <ButtonGroup>
                                        <Tag tag={"y+"} onClick={e => {
                                            e.stopPropagation();
                                            node.state.update({
                                                offsetY: state.offsetY + (e.ctrlKey ? 10 : 1)
                                            })
                                        }}/>
                                        <Tag tag={"y-"} onClick={e => {
                                            e.stopPropagation();
                                            node.state.update({
                                                offsetY: state.offsetY - (e.ctrlKey ? 10 : 1)
                                            })
                                        }}/>
                                    </ButtonGroup>

                                    <ButtonGroup>
                                        <Tag
                                            active={state.showLine}
                                            applyActiveTextStyle
                                            applyActiveScaling
                                            onClick={() => node.state.reverseBool("showLine")}
                                            tag={ctx => (
                                                <LineAxisRounded
                                                    sx={{
                                                        color: ctx.props.active ? ctx.t.col("color_primary") : undefined,
                                                        fontSize: 14
                                                    }}
                                                />
                                            )}
                                        />
                                        <Tag
                                            active={state.enableYAxisLabeling}
                                            applyActiveTextStyle
                                            applyActiveScaling
                                            onClick={() => node.state.reverseBool("enableYAxisLabeling")}
                                            tag={ctx => (
                                                <>
                                                    <DescriptiveTypography text={"y"} style={{
                                                        color: ctx.props.active ? ctx.t.col("color_primary") : undefined,
                                                    }}/>
                                                    <StraightenRounded
                                                        sx={{
                                                            transform: "rotate(90deg)",
                                                            color: ctx.props.active ? ctx.t.col("color_primary") : undefined,
                                                            fontSize: 14
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                        <Tag
                                            active={state.enableMouseAnnotations}
                                            applyActiveTextStyle
                                            applyActiveScaling
                                            onClick={() => node.state.reverseBool("enableMouseAnnotations")}
                                            tag={ctx => (
                                                <>
                                                    {/*
                                                    <DescriptiveTypography text={"y"} style={{
                                                        color: ctx.props.active ? ctx.t.col("color_primary") : undefined,
                                                    }}/>
                                                    */}
                                                    {
                                                        ctx.props.active && (
                                                            <GpsNotFixedRounded
                                                                sx={{
                                                                    color: ctx.props.active ? ctx.t.col("color_primary") : undefined,
                                                                    fontSize: 14
                                                                }}
                                                            />
                                                        ) || (
                                                            <GpsOffRounded
                                                                sx={{
                                                                    fontSize: 14
                                                                }}
                                                            />
                                                        )
                                                    }
                                                </>
                                            )}
                                        />
                                    </ButtonGroup>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    overflow: "hidden",
                                    gap: 4
                                }}>
                                    <Tag
                                        applyActiveScaling
                                        onClick={() => node.state.update({
                                            scaleY: 1,
                                            offsetY: 0,
                                            scaleX: 1
                                        })}
                                        tag={ctx => (
                                            <RotateRightRounded
                                                sx={{
                                                    fontSize: 14
                                                }}
                                            />
                                        )}
                                    />

                                    <Tag
                                        applyActiveScaling
                                        onClick={() => node.state.update(prevState => {
                                            prevState.plots.map(plot => {
                                                plot.plot = []
                                            });
                                            return prevState;
                                        })}
                                        tag={ctx => (
                                            <>
                                                <RotateRightRounded
                                                    sx={{
                                                        fontSize: 14
                                                    }}
                                                />
                                                <LineAxisRounded
                                                    sx={{
                                                        fontSize: 14
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        }
                    />
                )
            }/>
        </div>
    );
}

const PlotterYAxisMarker: FC<{
    percentage: number,
    value: number,
    offset: number,
    scale: number
}> = props => {
    const t = useTriton();
    const value = (1 / props.scale) * (props.value + props.offset);
    const valueText = value.toFixed(1);
    return (
        <div style={{
            position: "absolute",
            bottom: `calc(${props.percentage}%)`,
            transform: "translateY(50%)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 3
        }}>
            <div style={{
                height: 1,
                width: 4,
                backgroundColor: t.col("fg_muted")
            }}/>
            <DescriptiveTypography text={valueText} style={{
                fontSize: 10
            }}/>
        </div>
    );
}

const MouseFollower: FC = props => {
    const t = useTriton();
    const activeOpacity = .5;
    const initialOpacity = 0;
    const [state, setState] = useState({
        x: 0,
        y: 0,
        active: false
    });

    return (
        <div
            style={{
                zIndex: 10,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                touchAction: "none",
                cursor: "crosshair"
                // cursor: "cell"
                // backgroundColor: "rgba(96,255,199,0.16)"
            }}
            onMouseMove={event => {
                const rect = event.currentTarget.getBoundingClientRect();
                setState(prevState => ({
                    ...prevState,
                    x: event.clientX - rect.x,
                    y: event.clientY - rect.y
                }));
            }}
            onMouseEnter={() => setState(prevState => ({
                ...prevState,
                active: true
            }))}
            onMouseLeave={() => setState(prevState => ({
                ...prevState,
                active: false
            }))}
        >
            <motion.div
                style={{
                    position: "absolute",
                    width: 0,
                    height: "100%",
                    top: 0,
                    padding: "8px 0",
                    left: state.x,
                }}
                transition={{
                    duration: .1
                }}
                initial={{
                    opacity: initialOpacity
                }}
                animate={{
                    opacity: state.active ? activeOpacity : 0
                    // left: state.x,
                    // top: state.y,
                }}
                children={
                    <div style={{
                        height: "100%",
                        borderRight: `1px dashed ${t.col("fg_muted")}`,
                    }}/>
                }
            />
            <motion.div
                style={{
                    position: "absolute",
                    width: "100%",
                    height: 0,
                    top: state.y,
                    // padding: "8px 0",
                    left: 0,
                }}
                transition={{
                    duration: .1
                }}
                initial={{
                    opacity: initialOpacity
                }}
                animate={{
                    opacity: state.active ? activeOpacity : 0
                    // left: state.x,
                    // top: state.y,
                }}
                children={
                    <div style={{
                        width: "100%",
                        borderBottom: `1px dashed ${t.col("fg_muted")}`,
                    }}/>
                }
            />

            <motion.div
                style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    touchAction: "none",
                    userSelect: "none"
                }}
                initial={{
                    opacity: initialOpacity
                }}
                animate={{
                    opacity: state.active ? activeOpacity : 0
                }}
                children={
                    <DescriptiveTypography
                        text={`${state.x.toFixed(0)}, ${(75 - state.y).toFixed(0)}`}
                        style={{
                            fontSize: 10
                        }}
                    />
                }
            />
        </div>
    );
}
