import {Token} from "./Token";
import {SRCReader} from "../../../../../../ardai/unifiedGraphicsLanguage/SRCReader";



/**
 * __.__,_%
 * __.__,_"."
 */
export class TemplateReader {

    public read(template: string): Array<Token> {
        const scanner = new SRCReader(template);
        const tokens: Array<Token> = [];

        const backpatch = (patcher: (token: Token) => void) => {
            if (tokens.length === 0) return;
            patcher(tokens[tokens.length - 1]);
        }

        while (scanner.hasNext()) {
            const char = scanner.read();
            switch (char) {
                case "\"": {
                    while (scanner.hasNext() && scanner.peek() !== "\"") {
                        tokens.push(Token.newToken({
                            kind: "symbol",
                            spelling: scanner.read()
                        }));
                    }
                    if (scanner.peek() === "\"") scanner.skip();
                    break;
                }

                case ".": {
                    backpatch(token => {
                        token.annotations.add("dot")
                    });
                    break;
                }

                case ",": {
                    backpatch(token => {
                        token.annotations.add("comma")
                    });
                    break;
                }

                case "_": {
                    tokens.push(Token.newToken());
                    break;
                }

                default: {
                    tokens.push(Token.newToken({
                        kind: "symbol",
                        spelling: char
                    }));
                }
            }
        }

        return tokens;
    }
}
