import {NodeSetupInfo} from "../NodeSetupInfo";
import {Node} from "../../backend/Node";
import {v4} from "uuid";
import {FC, useEffect} from "react";
import {Tag} from "../../../ardai/components/Tag";
import {CableRounded, PlayArrowRounded, RefreshRounded} from "@mui/icons-material";
import {useNodeCanvasBackend} from "../NodeCanvasBackend";
import {NodeCanvasBackendEventTypes} from "../NodeCanvasBackendEventTypes";
import {NodeDisplay} from "../NodeDisplay";
import {PinMode} from "../../backend/Pin";
import {NodeEvent} from "../../backend/NodeEvent";
import {useTriton} from "../../../triton/TritonHooks";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import styled from "styled-components";
import {useAdvancedState} from "../../../ardai/hooks/StaticStateHook";

export type NodeWrapperState = {
    wrappedNode?: string,
}

/**
 * TODO: Move to system/ dir
 */
export const NodeWrapper: NodeSetupInfo = {
    label: "NodeWrapper",
    classname: "system.wrapper",
    parameterConfig: [],
    factory: parameters => new Node<NodeWrapperState>({
        id: v4(),
        classname: "system.wrapper",
        label: "api",
        state: {
        },
        init() {
            const node = this;

        },
        customRenderer: node => (
            <NodeWrapperDisplay
                node={node}
            />
        )
    })
}

const StyledNodeWrapperDisplay = styled.div`
    
`;

type NodeWrapperDisplayState = {
    isInEditMode: boolean
}

const NodeWrapperDisplay: FC<{
    node: Node<NodeWrapperState>
}> = props => {
    const t = useTriton();
    const backend = useNodeCanvasBackend();
    const node = props.node;
    const s = node.state.state;
    const env = node.env;
    const isReady = s.wrappedNode !== undefined;
    const wrappedNode: Node | undefined = s.wrappedNode === undefined ? undefined : env.getNodeByID(s.wrappedNode!);

    const generatePinRelays = () => {
        if (wrappedNode === undefined) return;
        const pins = node.pins;
        wrappedNode.pins.getAllPins().forEach(pin => {
            pins[pin.mode === PinMode.IN ? "in" : "out"](pin.label);
        });
        backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, new NodeEvent());
    }

    // TODO: Does it even do something?
    useEffect(() => backend.events.observe(
        NodeCanvasBackendEventTypes.GENERIC,
        NodeCanvasBackendEventTypes.GRAPH_UPDATE
    ).on(() => {
        generatePinRelays();
    }).destructor, []);

    const [state, ctx] = useAdvancedState<NodeWrapperDisplayState>({
        initial: {
            isInEditMode: true
        }
    }).stateWithCtx;


    if (!isReady) {
        return (
            <Tag
                tag={<PlayArrowRounded sx={{ fontSize: 12 }}/>}
                onClick={() => {
                    const targetNodeId = backend.state.ui.selection.prime.selectedNodeId;
                    if (targetNodeId === undefined) return;

                    node.state.update({
                        wrappedNode: targetNodeId
                    })

                    ctx.update({
                        isInEditMode: false
                    })
                    const targetNode = env.getNodeByID(targetNodeId);

                    node.setLabelAppendix(targetNode.config.label);
                    targetNode.isHidden = true;

                    backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, new NodeEvent())
                }}
            />
        );
    }

    return (
        <StyledNodeWrapperDisplay style={{
            margin: "0 -16px",
            backgroundColor: t.col("bg_modal")
        }}>
            <NodeDisplay
                node={wrappedNode!}
                isPreview
                shouldShowHeader={false}
            />

            <div style={{
                width: "100%",
                padding: "0 8px",
                display: "flex",
                justifyContent: "end"
            }} children={
                <DescriptiveTypography
                    enableUnderlineOnHover
                    text={state.isInEditMode ? "ok" : "edit"}
                    style={{
                        fontSize: 10,
                        cursor: "pointer"
                    }}
                    baseProps={{
                        onClick: () => {
                            ctx.reverseBool("isInEditMode")
                        }
                    }}
                />
            }/>

            { state.isInEditMode && (
                <div style={{
                    display: "flex",
                    gap: 4,
                    padding: "0 8px"
                }}>
                    <Tag
                        tag={<CableRounded sx={{fontSize: 12}}/>}
                        onClick={generatePinRelays}
                    />
                    <Tag
                        tag={<RefreshRounded sx={{fontSize: 12}}/>}
                        onClick={() => {
                            const targetNode = env.getNodeByID(node.state.state.wrappedNode!);
                            node.state.update({
                                wrappedNode: undefined
                            })
                            node.setLabelAppendix("");
                            targetNode.isHidden = false;
                            backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, new NodeEvent())

                            node.pins.getAllPins().forEach(pin => {
                                pin.destroy();
                            });

                            backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, new NodeEvent())
                        }}
                    />
                </div>
            )}
        </StyledNodeWrapperDisplay>
    );
}
