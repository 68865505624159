import {NodeSetupInfo} from "../NodeSetupInfo";
import {Node} from "../../backend/Node";
import {v4} from "uuid";
import {PinMode} from "../../backend/Pin";
import {DefaultCharacterSymbols} from "../DefaultCharacterSymbols";

export const NodeStateDecoder: NodeSetupInfo = {
    label: "NodeStateDecoder",
    classname: "state-decoder",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "state-decoder",
        label: "state decoder",
        defInPins: [DefaultCharacterSymbols.clockPinKey, "#"],
        init() {


            const decodedStateBank = this.pins.createPinBank("state", {
                label: "state",
                groups: ["output"],
                defaultPinMode: PinMode.OUT,
                dynamicSizeFlag: true,
                hideLabelFlag: false
            })

            this.pins.in(DefaultCharacterSymbols.clockPinKey).attachOnRead(() => {
                const target: Node | undefined  = this.pins.in("#").inputConnections[0]?.node;
                if (target === undefined) return;
                const state = target.state.state;
                Object.entries(state).forEach(([key, value]) => {
                    decodedStateBank.pin(key).write(value);
                });
            })
        },
    })
}
