import React, {CSSProperties, FC, useContext, useEffect, useRef} from "react";
import styled from "styled-components";
import {Node} from "../backend/Node";
import {ReactMaster} from "../../ReactAPIBridgeUtils";
import {nodeCanvasBackendBridge, useNodeCanvasBackend} from "./NodeCanvasBackend";
import {Tag} from "../../ardai/components/Tag";
import _ from "lodash";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {v4} from "uuid";
import {useForceRenderFunc} from "../../ForceRerenderHook";
import {BitField} from "./nodes/inputs/BitField";
import {SineWaveGen} from "./nodes/mathematics/SineWaveGen";
import {Counter} from "./nodes/Counter";
import {WavePlotter} from "./nodes/visualization/WavePlotter";
import {RandomGen} from "./nodes/mathematics/RandomGen";
import {Multiplier} from "./nodes/mathematics/Multiplier";
import {Adder} from "./nodes/mathematics/Adder";
import {LED} from "./nodes/colors/LED";
import {Oscillator} from "./nodes/Oscillator";
import {Boolean} from "./nodes/inputs/Boolean";
import {HSVtoRGBConvertor} from "./nodes/colors/HSVtoRGBConvertor";
import {Switch} from "./nodes/Switch";
import {Multiplexer} from "./nodes/Multiplexer";
import {BitFieldArray} from "./nodes/inputs/BitFieldArray";
import {NodeCanvasBackendEventTypes} from "./NodeCanvasBackendEventTypes";
import {Comparer} from "./nodes/Comparer";
import {CReg} from "./nodes/CReg";
import {Divider} from "./nodes/mathematics/Divider";
import {Dot} from "../../ardai/components/Dot";
import {useTriton} from "../../triton/TritonHooks";
import {AutoSizeDisplay} from "./nodes/visualization/AutoSizeDisplay";
import {IEEE754FP32} from "./nodes/_deprecated/IEEE754FP32";
import {NumericInput} from "./nodes/inputs/NumericInput";
import {AbsoluteNumeric} from "./nodes/mathematics/AbsoluteNumeric";
import {SignalFrequencySensor} from "./nodes/sensors/SignalFrequencySensor";
import {AdvancedPlotter} from "./nodes/visualization/AdvancedPlotter";
import {Menu} from "../../ardai/components/Menu";
import {AdvancedOscillator} from "./nodes/AdvancedOscillator";
import {NodeCanvasMain} from "./NodeCanvasMain";
import {SimplePinLogger} from "./nodes/visualization/SimplePinLogger";
import {ManualPulse} from "./nodes/ManualPulse";
import {SystemEnvironmentVariable} from "./nodes/system/SystemEnvironmentVariable";
import {NodeSystemStdOut} from "./nodes/system/NodeSystemStdOut";
import {NodePort} from "./nodes/system/NodePort";
import {NodeEnvironment} from "../host/NodeEnvironment";
import {OutputPortConfig} from "./nodes/system/OutputPortConfig";
import {ArithmeticComparer} from "./nodes/arithmetic/ArithmeticComparer";
import {IfCondition} from "./nodes/logic/IfCondition";
import {NodeHttpRequest} from "./nodes/network/NodeHttpRequest";
import {NodeStringInput} from "./nodes/inputs/NodeStringInput";
import {Inflatable} from "../backend/serialization/v2/Inflatable";
import {ArrayLinkImpl} from "../../../std/Accumulate";
import {SaveStateNode} from "../backend/serialization/v2/SaveStateNode";
import {NodeSetupInfo} from "./NodeSetupInfo";
import {InflationContext} from "../backend/serialization/v2/InflationContext";
import {
    ClearAllRounded,
    DarkModeRounded,
    DataObjectRounded,
    FormatShapesRounded,
    FullscreenExitRounded,
    FullscreenRounded,
    LightModeRounded,
    MoreHorizRounded,
    MotionPhotosAuto,
    MotionPhotosAutoRounded,
    MotionPhotosOffRounded, ScienceRounded, VisibilityOffOutlined,
    VisibilityRounded
} from "@mui/icons-material";
import {ButtonGroup} from "../../ardai/components/ButtonGroup";
import {triton} from "../../triton/Triton";
import {TritonDefaults} from "../../triton/TritonDefaults";
import {AdvancedNumericInput} from "./nodes/inputs/AdvancedNumericInput";
import {NodeEnumSelector} from "./nodes/NodeEnumSelector";
import {NodeMathMap} from "./nodes/mathematics/NodeMathMap";
import {NodeTBAdapter} from "./nodes/tb/NodeTBAdapter";
import {NodeCableTieSingle} from "./nodes/organization/NodeCableTieSingle";
import {NodeFrequencyToPeriodConverter} from "./nodes/NodeFrequencyToPeriodConverter";
import {NodeSubCanvasPrototype} from "./nodes/prototyping/NodeSubCanvasPrototype";
import {NodeQueue} from "./nodes/data/NodeQueue";
import {MenuButton} from "../../ardai/components/MenuButton";
import {NodeT2I} from "./nodes/tests/sdwui/NodeT2I";
import {NodeObjectToJSON} from "./nodes/objects/NodeObjectToJSON";
import {NodeObjectAccessor} from "./nodes/objects/NodeObjectAccessor";
import {NodeArrayAccessor} from "./nodes/objects/NodeArrayAccessor";
import {NodeImageDisplay} from "./nodes/visualization/NodeImageDisplay";
import {NodeStringFormat} from "./nodes/strings/NodeStringFormat";
import {NodeUnixTimestamp} from "./nodes/time/NodeUnixTimestamp";
import {NodeSimpleFileDownload} from "./nodes/files/NodeSimpleFileDownload";
import {NodeFetch} from "./nodes/network/NodeFetch";
import {NodePLC} from "./nodes/logic/plc/NodePLC";
import {Std} from "../../../Std";
import also = Std.also;
import {MenuDivider} from "@szhsin/react-menu";
import {MenuGroup} from "../../ardai/components/MenuGroup";
import {NodeJSONToObject} from "./nodes/objects/NodeJSONToObject";
import InfiniteViewer from "react-infinite-viewer";
import {NodeI2IUpscale} from "./nodes/tests/sdwui/NodeI2IUpscale";
import {NodeRunDispatch, RunDispatchFeedbackLoopType} from "./nodes/organization/NodeRunDispatch";
import {ProgrammableLogicController} from "./nodes/orchestration/ProgrammableLogicController";
import Editor from "@monaco-editor/react";
import {NodeGauge} from "./nodes/tests/sim/NodeGauge";
import {NodeDashboard, NodeDashboardDisplay} from "./nodes/visualization/NodeDashboard";
import {NodeFluidTank} from "./nodes/tests/sim/NodeFluidTank";
import {NodeFluidPump} from "./nodes/tests/sim/NodeFluidPump";
import {NodeCombustionEngine} from "./nodes/tests/sim/NodeCombustionEngine";
import {NodeDisplay} from "./nodes/visualization/NodeDisplay";
import {NodeImageBase64ToBlobConvertor} from "./nodes/conversion/NodeImageBase64ToBlobConvertor";
import {NodePromptTranspiler} from "./nodes/tests/sdwui/NodePromptTranspiler";
import {NodeDynamicConfig} from "./nodes/inputs/NodeDynamicConfig";
import {NodeCooler} from "./nodes/tests/sim/NodeCooler";
import {
    NodeAdvancedSevenSegmentDisplay
} from "./nodes/visualization/advancedSevenSegmentDisplay/NodeAdvancedSevenSegmentDisplay";
import {AttachmentGroupMember} from "../backend/attachments/defaults/AttachmentGroupMember";
import {NodeStateDecoder} from "./nodes/NodeStateDecoder";
import {NodeDecimalShifter} from "./nodes/mathematics/NodeDecimalShifter";
import {NodeWrapper} from "./nodes/NodeWrapper";
import {NodeImageBuffer} from "./nodes/images/NodeImageBuffer";
import {NodeImageInverter} from "./nodes/images/NodeImageInverter";
import {NodeImageGrayscale} from "./nodes/images/NodeImageGrayscale";
import {NodePipelineStepController} from "./nodes/images/NodePipelineStepController";
import {NodeImageOverlay} from "./nodes/images/NodeImageOverlay";

const StyledNodeCanvas = styled.div`
  // overflow: hidden;
  
  .node-ui {
    display: grid;
    grid-template-rows: min-content auto min-content;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    overflow: hidden;
    
    .node-ui-header {
      //position: relative;
      width: 100%;
      height: 64px;
      
      overflow-x: scroll;
      display: flex;
      gap: 4px;
      align-items: center;
      // background-color: rebeccapurple;
    }

    .view-main-section {
      display: grid;
      grid-template-columns: auto min-content;
    }
    
    // .node-canvas-wrapper {
    //   overflow: scroll;
    //   width: 100%;
    //   height: 100%;
    //   .node-canvas {}
    // }
    
    .node-ui-footer {
      position: relative;
      width: 100%;
      // overflow-x: scroll;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: space-between;
      // height: 64px;
      // background-color: rebeccapurple;
      
      .left {}
      
      .right {
        display: flex;
        align-items: center;
        gap: 4px
      }
    }
  }
  
  .layer {
    &.line-layer {
      z-index: 90;
    }
  }
`;

export type NodeCanvasProps = {
    env: NodeEnvironment

    /**
     * @deprecated
     */
    nodes: Array<Node>
}

export const NodeCanvas: FC<NodeCanvasProps> = props => {
    return (
        <ReactMaster fx={nodeCanvasBackendBridge}>
            <NodeCanvasSub {...props}/>
        </ReactMaster>
    );
}

const NodeCanvasSub: FC<NodeCanvasProps> = props => {
    const backend = useNodeCanvasBackend();

    // TODO: Don't be stupid... that's a "not majorly intelligent" way of dealing with syncing backend~environment
    backend.environment = props.env;

    // backend.nodes = props.nodes;
    const [backendState, setBackendState] = useContext(nodeCanvasBackendBridge.stateCtx);

    const forceRender = useForceRenderFunc();
    const t = useTriton();


    // TODO: May remove?
    useEffect(() => {
        props.nodes.forEach(node => {
            node.reset();
            // node.backend = backend;
        })
    }, []);

    useEffect(() => {
        forceRender();
    }, [backendState])

    useEffect(() => backend.events.observe(
        NodeCanvasBackendEventTypes.GRAPH_UPDATE,
        // TODO: Can it be more fine-grained?
        NodeCanvasBackendEventTypes.GENERIC
    ).on((key, data) => {
        forceRender();
    }).destructor, []);

    const nodeLibRef = useRef(nodeLib);

    return (
        <StyledNodeCanvas id={"_StyledNodeCanvas"}>
            <div className={"node-ui"}>
                <div className={"node-ui-header"}>
                    <Menu opener={
                        <Tag tag={"organization"}/>
                    }>
                        <NodeLibraryCategoryMenuEntry
                            info={NodeCableTieSingle}
                            label={"cable tie"}
                        />
                        <NodeLibraryCategoryMenuEntry
                            info={NodeSubCanvasPrototype}
                            label={"sub canvas"}
                        />

                        <MenuGroup title={"run dispatches"}>
                            <div>
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeRunDispatch}
                                    label={"run (open loop)"}
                                    parameters={also(new Map<string, any>(), function () {
                                        this.set("feedback-loop-type", RunDispatchFeedbackLoopType.OPEN);
                                        this.set("is-colored", true);
                                    })}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeRunDispatch}
                                    label={"run (closed loop)"}
                                    parameters={also(new Map<string, any>(), function () {
                                        this.set("feedback-loop-type", RunDispatchFeedbackLoopType.CLOSED);
                                        this.set("is-colored", true);
                                    })}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeRunDispatch}
                                    label={"run (closed loop, controlled)"}
                                    parameters={also(new Map<string, any>(), function () {
                                        this.set("feedback-loop-type", RunDispatchFeedbackLoopType.CLOSED);
                                        this.set("is-colored", true);
                                        this.set("is-controlled", true);
                                    })}
                                />
                            </div>
                        </MenuGroup>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"objects"}/>
                    }>
                        <NodeLibraryCategoryMenuEntry info={NodeObjectToJSON}/>
                        <NodeLibraryCategoryMenuEntry info={NodeJSONToObject}/>
                        <NodeLibraryCategoryMenuEntry info={NodeObjectAccessor}/>
                        <NodeLibraryCategoryMenuEntry info={NodeArrayAccessor}/>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"strings"}/>
                    }>
                        <NodeLibraryCategoryMenuEntry info={NodeStringFormat}/>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"system utilities"}/>
                    }>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 8
                        }}>
                            <NodeLibraryCategoryMenuEntry
                                info={NodeWrapper}
                                label={"wrapper"}
                            />

                            <MenuGroup title={"Debugging utilities"}>
                                <div>
                                    <NodeLibraryCategoryMenuEntry
                                        info={NodeTBAdapter}
                                        label={"test-bench adapter"}
                                    />
                                    <NodeLibraryCategoryMenuEntry
                                        info={NodeStateDecoder}
                                        label={"state decoder"}
                                    />
                                    <NodeLibraryCategoryMenuEntry
                                        info={SimplePinLogger}
                                        label={"pin logger (simple)"}
                                    />
                                </div>
                            </MenuGroup>

                            <MenuGroup title={"Sub node utilities"}>
                                <div>
                                    <NodeLibraryCategoryMenuEntry
                                        info={NodePort}
                                        label={"Ports (input)"}
                                    />
                                    <NodeLibraryCategoryMenuEntry
                                        info={OutputPortConfig}
                                        label={"Ports (output)"}
                                    />
                                </div>
                            </MenuGroup>

                            <MenuGroup title={"Sensors"}>
                                <div>
                                    <NodeLibraryCategoryMenuEntry
                                        info={SignalFrequencySensor}
                                        label={"frequency sensor"}
                                    />
                                </div>
                            </MenuGroup>
                        </div>

                        <div>
                            <NodeLibraryCategoryMenuEntry
                                info={SystemEnvironmentVariable}
                                label={"Sys env var"}
                            />
                            <NodeLibraryCategoryMenuEntry
                                info={NodeSystemStdOut}
                                label={"Std out"}
                            />
                            <NodeLibraryCategoryMenuEntry
                                info={ManualPulse}
                                label={"Manual data pusher"}
                            />
                            <NodeLibraryCategoryMenuEntry
                                info={NodeUnixTimestamp}
                                label={"Unix timestamp"}
                            />
                        </div>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"files"}/>
                    }>
                        <NodeLibraryCategoryMenuEntry info={NodeSimpleFileDownload}/>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"input"}/>
                    }>
                        <MenuGroup title={"strings"}>
                            <div>
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeStringInput}
                                    label={"str input"}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeStringInput}
                                    label={"str input (multiline)"}
                                    parameters={also(new Map<string, any>(), function () {
                                        this.set("multiline-mode", true);
                                    })}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeStringInput}
                                    label={"str input (multiline, wide)"}
                                    parameters={also(new Map<string, any>(), function () {
                                        this.set("multiline-mode", true);
                                        this.set("width", 300);
                                    })}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeStringInput}
                                    label={"str input (multiline, wide, setter)"}
                                    parameters={also(new Map<string, any>(), function () {
                                        this.set("multiline-mode", true);
                                        this.set("enable-setter", true);
                                        this.set("width", 300);
                                    })}
                                />
                            </div>
                        </MenuGroup>

                        <MenuGroup title={"numbers"}>
                            <div>
                                <NodeLibraryCategoryMenuEntry
                                    info={NumericInput}
                                    label={"num input"}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={AdvancedNumericInput}
                                    label={"num input (advanced)"}
                                />
                            </div>
                        </MenuGroup>

                        <MenuGroup title={"booleans"}>
                            <div>
                                <NodeLibraryCategoryMenuEntry
                                    info={Boolean}
                                    label={"boolean input"}
                                />
                            </div>
                        </MenuGroup>

                        <MenuGroup title={"batch inputs"}>
                            <div>
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeDynamicConfig}
                                    label={"config"}
                                />
                            </div>
                        </MenuGroup>

                        <div style={{
                            display: "flex",
                            gap: 4,
                            alignItems: "center",
                            marginTop: 8
                        }}>
                            <Menu opener={
                                <Tag tag={"bit fields"}/>
                            }>
                                <div style={{
                                    display: "flex",
                                    gap: 4,
                                    alignItems: "center"
                                }}>
                                    <Tag tag={"8-bit field"} onClick={() => {
                                        const parameters = new Map<string, any>();
                                        parameters.set("length", 8);
                                        parameters.set("initial", 0);
                                        const node = BitField.factory(parameters);
                                        backend.environment.registerNode(node);
                                        forceRender();
                                    }}/>

                                    <Tag tag={"4-bit field"} onClick={() => {
                                        const parameters = new Map<string, any>();
                                        parameters.set("length", 4);
                                        parameters.set("initial", 0);
                                        const node = BitField.factory(parameters);
                                        backend.environment.registerNode(node);
                                        forceRender();
                                    }}/>

                                    <Tag tag={"3-bit field"} onClick={() => {
                                        const parameters = new Map<string, any>();
                                        parameters.set("length", 3);
                                        parameters.set("initial", 0);
                                        const node = BitField.factory(parameters);
                                        backend.environment.registerNode(node);
                                        forceRender();
                                    }}/>

                                    <Dot/>

                                    <Tag tag={"1-bit field"} onClick={() => {
                                        const parameters = new Map<string, any>();
                                        parameters.set("length", 1);
                                        parameters.set("initial", 0);
                                        const node = BitField.factory(parameters);
                                        backend.environment.registerNode(node);
                                        forceRender();
                                    }}/>
                                </div>
                            </Menu>

                            <Dot/>

                            <Tag tag={"fp32"} onClick={() => {
                                const parameters = new Map<string, any>();
                                const node = IEEE754FP32.factory(parameters);
                                backend.environment.registerNode(node);
                                forceRender();
                            }}/>

                            <Tag tag={"enum"} onClick={() => {
                                const parameters = new Map<string, any>();
                                const node = NodeEnumSelector.factory(parameters);
                                backend.environment.registerNode(node);
                                forceRender();
                            }}/>
                        </div>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"oscillators"}/>
                    }>
                        <div>
                            <NodeLibraryCategoryMenuEntry
                                info={Oscillator}
                                label={"oscillator"}
                            />
                            <NodeLibraryCategoryMenuEntry
                                info={AdvancedOscillator}
                                label={"oscillator *"}
                            />
                        </div>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"network"}/>
                    }>
                        <div>
                            <NodeLibraryCategoryMenuEntry
                                info={NodeFetch}
                            />
                            <NodeLibraryCategoryMenuEntry
                                info={NodeHttpRequest}
                                label={"http request"}
                            />
                        </div>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"math"}/>
                    }>
                        <MenuGroup title={"arithmetic"}>
                            <div>
                                <NodeLibraryCategoryMenuEntry
                                    info={AbsoluteNumeric}
                                    label={"abs"}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={Adder}
                                    label={"adder"}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={Multiplier}
                                    label={"multiplier"}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={Divider}
                                    label={"divider"}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeDecimalShifter}
                                    label={"decimal shifter"}
                                />
                            </div>
                        </MenuGroup>

                        <NodeLibraryCategoryMenuEntry
                            info={ArithmeticComparer}
                            label={"arithmetic comparer"}
                        />
                        <NodeLibraryCategoryMenuEntry
                            info={RandomGen}
                            label={"random gen"}
                        />
                        <NodeLibraryCategoryMenuEntry
                            info={Counter}
                            label={"counter"}
                        />
                        <NodeLibraryCategoryMenuEntry
                            info={NodeMathMap}
                            label={"map"}
                        />
                        <NodeLibraryCategoryMenuEntry
                            info={SineWaveGen}
                            label={"wave gen"}
                        />

                        <MenuGroup>
                            <Tag tag={"wave gen (standalone)"} onClick={() => {
                                const parameters = new Map<string, any>();

                                // create nodes
                                const oscillator = AdvancedOscillator.factory(parameters);
                                const counter = Counter.factory(parameters);
                                const wave = SineWaveGen.factory(parameters);
                                // configure node group
                                counter.isHidden = true;
                                wave.isHidden = true;
                                oscillator._testSubNode = counter.id;
                                counter._testSubNode = wave.id;
                                // wire nodes
                                oscillator.pins.out("c").connect(counter.pins.in("c"));
                                counter.pins.out("i").connect(wave.pins.in("t"));

                                [oscillator, counter, wave].forEach(node => {
                                    backend.environment.registerNode(node);
                                })
                                forceRender();
                            }}/>
                        </MenuGroup>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"visualization"}/>
                    }>
                        <div>
                            <NodeLibraryCategoryMenuEntry
                                info={NodeDashboard}
                            />
                            <NodeLibraryCategoryMenuEntry
                                info={NodeGauge}
                            />
                            <NodeLibraryCategoryMenuEntry
                                info={NodeImageDisplay}
                            />
                            <NodeLibraryCategoryMenuEntry
                                info={NodeAdvancedSevenSegmentDisplay}
                            />
                        </div>

                        <MenuGroup title={"displays"}>
                            <div>
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeDisplay}
                                    label={"display (1x20)"}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeDisplay}
                                    label={"display (2x20)"}
                                    parameters={also(new Map<string, any>(), function () {
                                        this.set("resY", 2);
                                    })}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={AutoSizeDisplay}
                                    label={"display (multiline)"}
                                />
                            </div>
                        </MenuGroup>

                        <MenuGroup title={"plotters"}>
                            <div>
                                <NodeLibraryCategoryMenuEntry
                                    info={WavePlotter}
                                    label={"wave plotter"}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={AdvancedPlotter}
                                    label={"wave plotter (v2/advanced)"}
                                />
                            </div>
                        </MenuGroup>

                        <MenuGroup title={"rgb"}>
                            <div>
                                <NodeLibraryCategoryMenuEntry
                                    info={HSVtoRGBConvertor}
                                    label={"hsv->rgb"}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={LED}
                                    label={"led"}
                                />
                            </div>
                        </MenuGroup>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"data structures"}/>
                    }>
                        <div>
                            <NodeLibraryCategoryMenuEntry
                                info={CReg}
                                label={"register (clocked)"}
                            />

                            <MenuGroup title={"queueing"}>
                                <div>
                                    <NodeLibraryCategoryMenuEntry
                                        info={NodeQueue}
                                        label={"queue"}
                                    />
                                    <NodeLibraryCategoryMenuEntry
                                        info={NodeQueue}
                                        label={"queue (without display)"}
                                        parameters={also(new Map<string, any>(), function () {
                                            this.set("no-display", true);
                                        })}
                                    />
                                    <NodeLibraryCategoryMenuEntry
                                        info={NodeQueue}
                                        label={"queue (simplistic, without display)"}
                                        parameters={also(new Map<string, any>(), function () {
                                            this.set("no-display", true);
                                            this.set("is-simplistic", true);;
                                        })}
                                    />
                                </div>
                            </MenuGroup>
                        </div>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"control flow"}/>
                    }>
                        <div style={{
                            display: "flex",
                            gap: 4,
                            alignItems: "center"
                        }}>
                            <Menu opener={
                                <Tag tag={"logic"}/>
                            }>
                                <NodeLibraryCategoryMenuEntry info={NodePLC}/>
                                <div style={{
                                    display: "flex",
                                    gap: 4,
                                    alignItems: "center"
                                }}>
                                    <Tag tag={"if"} onClick={() => {
                                        const parameters = new Map<string, any>();
                                        const node = IfCondition.factory(parameters);
                                        backend.environment.registerNode(node);
                                        forceRender();
                                    }}/>
                                </div>
                            </Menu>

                            <Dot/>

                            <Tag tag={"comparer"} onClick={() => {
                                const parameters = new Map<string, any>();
                                const node = Comparer.factory(parameters);
                                backend.environment.registerNode(node);
                                forceRender();
                            }}/>

                            <Tag tag={"switch"} onClick={() => {
                                const parameters = new Map<string, any>();
                                const node = Switch.factory(parameters);
                                backend.environment.registerNode(node);
                                forceRender();
                            }}/>

                            <Tag tag={"mux"} onClick={() => {
                                const parameters = new Map<string, any>();
                                const node = Multiplexer.factory(parameters);
                                backend.environment.registerNode(node);
                                forceRender();
                            }}/>
                        </div>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"conversions"}/>
                    }>
                        <div>
                            <MenuGroup title={"numeric"}>
                                <div>
                                    <NodeLibraryCategoryMenuEntry
                                        label={"freq->period"}
                                        info={NodeFrequencyToPeriodConverter}
                                    />
                                </div>
                            </MenuGroup>
                            <MenuGroup title={"image"}>
                                <div>
                                    <NodeLibraryCategoryMenuEntry
                                        label={"img-base64->blob"}
                                        info={NodeImageBase64ToBlobConvertor}
                                    />
                                </div>
                            </MenuGroup>
                        </div>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"orchestration"}/>
                    }>
                        <MenuGroup title={"coding"}>
                            <div>
                                <NodeLibraryCategoryMenuEntry
                                    label={"plc"}
                                    info={ProgrammableLogicController}
                                />
                            </div>
                        </MenuGroup>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"sd"}/>
                    }>
                        <MenuGroup title={"basics"}>
                            <div>
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeT2I}
                                    label={"t2i"}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={NodeI2IUpscale}
                                    label={"upscale (i2i-based)"}
                                />
                                <NodeLibraryCategoryMenuEntry
                                    info={NodePromptTranspiler}
                                    label={"prompt transpiler"}
                                />
                            </div>
                        </MenuGroup>

                        <MenuGroup>
                            {/* TODO: Implement */}
                            <Tag tag={"t2i loop"} onClick={() => {
                                const parameters = new Map<string, any>();

                                // create nodes
                                const oscillator = AdvancedOscillator.factory(parameters);
                                const counter = Counter.factory(parameters);
                                const wave = SineWaveGen.factory(parameters);
                                // configure node group
                                counter.isHidden = true;
                                wave.isHidden = true;
                                oscillator._testSubNode = counter.id;
                                counter._testSubNode = wave.id;
                                // wire nodes
                                oscillator.pins.out("c").connect(counter.pins.in("c"));
                                counter.pins.out("i").connect(wave.pins.in("t"));

                                [oscillator, counter, wave].forEach(node => {
                                    node.init();
                                    backend.environment.registerNode(node);
                                })
                                forceRender();
                            }}/>
                        </MenuGroup>
                    </Menu>

                    <Menu opener={
                        <Tag tag={"sim"}/>
                    }>
                        { simTestNodes.map((node, idx) => {
                            return (
                                <NodeLibraryCategoryMenuEntry
                                    key={idx}
                                    info={node}
                                />
                            );
                        }) }

                        <MenuGroup title={"presets"}>
                            <Tag tag={"tank-to-tank"} onClick={() => {
                                const parameters = new Map<string, any>();

                                // create nodes
                                const tankSrc = NodeFluidTank.factory(parameters);
                                const pump = NodeFluidPump.factory(parameters);
                                const tankDst = NodeFluidTank.factory(new Map([[
                                    "levelPercentage", 0
                                ]]));

                                // create display nodes
                                const srcLevelDisplay = NodeAdvancedSevenSegmentDisplay.factory(new Map([[
                                    "template", "l______._",
                                ]]));

                                const dstLevelDisplay = NodeAdvancedSevenSegmentDisplay.factory(new Map([[
                                    "template", "l______._"
                                ]]));

                                srcLevelDisplay.isHidden = true;
                                tankSrc._testSubNode = srcLevelDisplay.id;

                                dstLevelDisplay.isHidden = true;
                                tankDst._testSubNode = dstLevelDisplay.id;

                                [srcLevelDisplay, dstLevelDisplay, tankSrc, pump, tankDst].forEach(node => {
                                    node.init();
                                    backend.environment.registerNode(node);
                                })

                                tankSrc.pins.out("#").connect(pump.pins.in("#"));
                                pump.pins.out("#").connect(tankDst.pins.in("#"));

                                tankSrc.state.update({
                                    enableDisplay: true
                                })

                                tankDst.state.update({
                                    enableDisplay: true
                                });
                                forceRender();
                            }}/>

                            <Tag tag={"tank + info"} onClick={() => {
                                const parameters = new Map<string, any>();

                                // create nodes
                                const tank = NodeFluidTank.factory(parameters);

                                // create display nodes
                                const l = NodeAdvancedSevenSegmentDisplay.factory(new Map([[
                                    "template", "l______._",
                                ]]));

                                const lPercentage = NodeAdvancedSevenSegmentDisplay.factory(new Map([[
                                    "template", "%___.__"
                                ]]));

                                [tank, l, lPercentage].forEach(node => {
                                    node.init();
                                    backend.environment.registerNode(node);
                                })

                                l.isHidden = true;
                                tank._testSubNode = l.id;

                                lPercentage.isHidden = true;
                                l._testSubNode = lPercentage.id;
                                lPercentage.attachments.set("group-membership", {
                                    groupParentNodeId: l.id
                                } satisfies AttachmentGroupMember);

                                tank.pins.out("l").connect(l.pins.in("s"));
                                tank.pins.out("l%").connect(lPercentage.pins.in("s"));

                                tank.pins.in("±").onRead(0);

                                backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, undefined);



                                // forceRender();
                            }}/>
                        </MenuGroup>

                    </Menu>

                    <Dot/>

                    <Menu opener={
                        <Tag tag={"s-ram"}/>
                    }>
                        <div style={{
                            display: "flex",
                            gap: 4,
                            alignItems: "center"
                        }}>
                            <Tag tag={"8x8-bit SRAM"} onClick={() => {
                                const parameters = new Map<string, any>();
                                const node = BitFieldArray.factory(parameters);
                                backend.environment.registerNode(node);
                                forceRender();
                            }}/>

                            <Tag tag={"8x2-bit SRAM"} onClick={() => {
                                const parameters = new Map<string, any>();
                                parameters.set("word-len", 8);
                                parameters.set("block-len", 2);
                                const node = BitFieldArray.factory(parameters);
                                backend.environment.registerNode(node);
                                forceRender();
                            }}/>

                            <Tag tag={"4x4-bit SRAM"} onClick={() => {
                                const parameters = new Map<string, any>();
                                parameters.set("word-len", 4);
                                parameters.set("block-len", 4);
                                const node = BitFieldArray.factory(parameters);
                                backend.environment.registerNode(node);
                                forceRender();
                            }}/>

                            <Tag tag={"8x1-bit SRAM"} onClick={() => {
                                const parameters = new Map<string, any>();
                                parameters.set("word-len", 1);
                                parameters.set("block-len", 8);
                                const node = BitFieldArray.factory(parameters);
                                backend.environment.registerNode(node);
                                forceRender();
                            }}/>
                        </div>
                    </Menu>

                    <Dot/>

                    <Menu menuProps={{
                        direction: "bottom"
                    }} opener={
                        <Tag tag={
                            //
                            // <div style={{
                            //     display: "flex",
                            //     flexDirection: "row",
                            //     alignItems: "center",
                            //     gap: 6
                            // }}>
                            //     <ScienceRounded sx={{
                            //         fontSize: 10
                            //     }}/>
                            //     <DescriptiveTypography text={"Experimental"} noSelect/>
                            // </div>
                            //
                            "Experimental"
                        }/>
                    }>
                        <MenuGroup title={"Experimental nodes"}>
                            <div>
                                {experimentalNodes.map((node, idx) => {
                                    return (
                                        <NodeLibraryCategoryMenuEntry
                                            key={idx}
                                            info={node}
                                        />
                                    );
                                })}
                            </div>
                        </MenuGroup>
                    </Menu>
                </div>

                <NodeCanvasMain/>

                <div className={"node-ui-footer"}>

                    <div className={"left"}>
                        <div style={{
                            position: "absolute",
                            left: 1,
                            // bottom: "calc(100% + 8px)"
                            bottom: "0"
                        }}>
                            <NodeDashboardDisplay/>
                        </div>
                    </div>

                    <div className={"right"}>
                        <ButtonGroup>
                            <Tag tag={<DarkModeRounded sx={{ fontSize: 14 }}/>} onClick={() => {
                                triton().switchTheme(TritonDefaults.theme.id);
                            }}/>
                            <Tag tag={
                                <>
                                    <DarkModeRounded sx={{ fontSize: 14 }}/>
                                    <DescriptiveTypography text={"crimson"}/>
                                </>
                            } onClick={() => {
                                triton().switchTheme(TritonDefaults.crimsonFutureTheme.id);
                            }}/>
                            <Tag tag={
                                <>
                                    <DarkModeRounded sx={{ fontSize: 14 }}/>
                                    <DescriptiveTypography text={"blue"}/>
                                </>
                            } onClick={() => {
                                triton().switchTheme(TritonDefaults.blueFutureTheme.id);
                            }}/>
                            <Tag tag={
                                <>
                                    <DarkModeRounded sx={{ fontSize: 14 }}/>
                                    <DescriptiveTypography text={"green"}/>
                                </>
                            } onClick={() => {
                                triton().switchTheme(TritonDefaults.greenFutureTheme.id);
                            }}/>
                            <Tag tag={
                                <>
                                    <DarkModeRounded sx={{ fontSize: 14 }}/>
                                    <DescriptiveTypography text={"purple"}/>
                                </>
                            } onClick={() => {
                                triton().switchTheme(TritonDefaults.purpleFutureTheme.id);
                            }}/>
                            <Tag tag={<LightModeRounded sx={{ fontSize: 14 }}/>} onClick={() => {
                                triton().switchTheme(TritonDefaults.lightTheme.id);
                            }}/>
                        </ButtonGroup>

                        <Dot/>

                        <ButtonGroup>
                            <Tag tag={<FullscreenRounded sx={{ fontSize: 14 }}/>} onClick={() => {
                                const canvas = document.getElementById("_StyledNodeCanvas")!;
                                canvas.style.position = "absolute";
                                canvas.style.padding = "16px";
                                canvas.style.top = "0px";
                                canvas.style.left = "0px";
                                canvas.style.height = "100vh";
                                canvas.style.width = "100vw";
                                canvas.style.zIndex = "1000";
                                canvas.style.backgroundColor = t.col("bg_main") ?? "#060607";
                                forceRender();
                            }}/>
                            <Tag tag={<FullscreenExitRounded sx={{ fontSize: 14 }}/>} onClick={() => {
                                const canvas = document.getElementById("_StyledNodeCanvas")!;
                                canvas.style.position = "unset";
                                canvas.style.height = "100%";
                                canvas.style.width = "100%";
                                canvas.style.zIndex = "unset";
                                canvas.style.backgroundColor = t.col("bg_main") ?? "#060607";
                                forceRender();
                            }}/>
                            <NodeWidgetEnableControlButton/>
                            <WireFlickeringControlButton/>
                        </ButtonGroup>

                        <Dot/>

                        <Menu opener={
                            <Tag applyActiveScaling tag={
                                <MoreHorizRounded sx={{
                                    fontSize: 14
                                }}/>
                            }/>
                        }>
                            <MenuButton text={"Clear canvas"} icon={<ClearAllRounded/>} onSelect={() => {
                                backend.environment.nodes = [];
                                forceRender();
                            }}/>
                            <MenuButton text={"Print snapshot"} onSelect={() => {
                                const serializedSave = localStorage.getItem("debug-node-snapshot");
                                if (serializedSave === null) throw new Error("serialized save is null");
                                const save = JSON.parse(serializedSave) as Array<SaveStateNode>;
                                console.log(save);
                                save.forEach((value, index) => {
                                    console.log(`${_.padStart(String(index), 3, " ")}`, value)
                                });
                            }}/>

                            <MenuButton text={"Print nodes"} onSelect={() => {
                                console.log(backend.environment.nodes);
                            }}/>
                        </Menu>

                        <Dot/>

                        {/*
                    <Tag tag={"toggle watermark"} onClick={() => {
                        ctx.reverseBool("showWatermark");
                    }}/>
                    */}

                        <ButtonGroup>
                            <Tag tag={"Deflate"} onClick={() => new ArrayLinkImpl(backend.environment.nodes)
                                .forEach(x => console.log("deflating node", x))
                                .map(node => (node as unknown as Inflatable).deflate?.())
                                .filter(x => x !== undefined)
                                .map(x => x!)
                                .accumulateAsDispatch<SaveStateNode[]>([], (x1, x2) => x1.push(x2))
                                .also(x => console.log(x))
                                .run(save => JSON.stringify(save))
                                .run(serializedSave => localStorage.setItem("debug-node-snapshot", serializedSave))
                            }/>

                            <Tag tag={"Inflate"} onClick={() => {
                                const serializedSave = localStorage.getItem("debug-node-snapshot");
                                if (serializedSave === null) throw new Error("serialized save is null");
                                const save = JSON.parse(serializedSave) as Array<SaveStateNode>;

                                function resolveNodeClass(classname: string): NodeSetupInfo {
                                    const nodeClass = nodeLib.find(node => node.classname === classname);
                                    if (nodeClass === undefined) throw new Error(`Class '${classname}' not found`);
                                    return nodeClass;
                                }

                                const savesWithNodes = new Map<SaveStateNode, Node>();

                                save.forEach(nodeSave => {
                                    const nodeData = nodeSave.data;
                                    const nodeClass = resolveNodeClass(nodeData.classname as string);
                                    const parameters = new Map<string, any>();
                                    // TODO: Prevent init fn to be called immediately
                                    const node = nodeClass.factory(parameters);
                                    node.id = nodeSave.id;
                                    backend.environment.registerNode(node);
                                    savesWithNodes.set(nodeSave, node);
                                });

                                // General inflation step (set the state)
                                Array.from(savesWithNodes.entries()).forEach(e => {
                                    (e[1] as unknown as Inflatable)["inflate"]?.(e[0].data, e[0], new InflationContext({}))
                                });

                                Array.from(savesWithNodes.entries()).forEach(e => {
                                    e[1].init();
                                });

                                // Wiring inflation step
                                Array.from(savesWithNodes.entries()).forEach(e => {
                                    (e[1] as unknown as Inflatable)["inflate"]?.(e[0].data, e[0], new InflationContext({
                                        phase: "wiring"
                                    }))
                                });

                                forceRender();
                            }}/>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </StyledNodeCanvas>
    );
}

const WireFlickeringControlButton: FC = props => {
    const backend = useNodeCanvasBackend();
    const enableFlickering = backend.state.ui.canvas.wiring.enableFlickering;
    const iconCSS: CSSProperties = { fontSize: 14 };
    return (
        <Tag applyActiveScaling tag={
            enableFlickering ? (
                <MotionPhotosAutoRounded sx={iconCSS}/>
            ) : (
                <MotionPhotosOffRounded sx={iconCSS}/>
            )
        } onClick={() => {
            backend.setState(prevState => {
                const newState = { ...prevState };
                newState.ui.canvas.wiring.enableFlickering = !prevState.ui.canvas.wiring.enableFlickering;
                return newState;
            });
        }}/>
    );
}

const NodeWidgetEnableControlButton: FC = props => {
    const backend = useNodeCanvasBackend();
    const enabled = backend.state.ui.canvas.nodes.widgets.enabled;
    const iconCSS: CSSProperties = { fontSize: 14 };
    return (
        <Tag applyActiveScaling tag={
            enabled ? (
                <VisibilityRounded sx={iconCSS}/>
            ) : (
                <VisibilityOffOutlined sx={iconCSS}/>
            )
        } onClick={() => {
            backend.setState(prevState => {
                const newState = { ...prevState };
                newState.ui.canvas.nodes.widgets.enabled = !prevState.ui.canvas.nodes.widgets.enabled;
                return newState;
            });
        }}/>
    );
}

const NodeLibraryCategoryMenuEntry: FC<{
    info: NodeSetupInfo,
    label?: string
    parameters?: Map<string, any>
}> = props => {
    const backend = useNodeCanvasBackend();

    const constructNode = () => {
        const parameters = props.parameters ?? new Map<string, any>();
        const node = props.info.factory(parameters);
        node.init();
        backend.environment.registerNode(node);
        backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, undefined);
    }

    return (
        <MenuButton
            keepOpenOnClick
            onSelect={constructNode}
            icon={
                <FormatShapesRounded/>
            }
            text={
                <DescriptiveTypography text={props.label ?? props.info.label}/>
            }
        />
    );
}

const simTestNodes: NodeSetupInfo[] = [
    NodeFluidTank,
    NodeFluidPump,
    NodeCombustionEngine,
    NodeCooler
]

const experimentalNodes: NodeSetupInfo[] = [
    NodeImageBuffer,
    NodeImageInverter,
    NodeImageGrayscale,
    NodeImageOverlay,
    NodePipelineStepController
]

const nodeLib = [
    NodeWrapper,
    // input
    Adder,
    Multiplier,
    NodeDecimalShifter,
    Divider,
    NumericInput,
    AdvancedNumericInput,
    // adv./configuration input
    NodeDynamicConfig,

    AutoSizeDisplay,
    NodeDisplay,
    ArithmeticComparer,
    IfCondition,
    // network / fetching
    NodeHttpRequest,
    NodeFetch,

    SignalFrequencySensor,
    NodePort,
    NodeSystemStdOut,
    OutputPortConfig,
    SystemEnvironmentVariable,
    AbsoluteNumeric,
    Adder,
    AdvancedOscillator,
    AdvancedPlotter,
    AutoSizeDisplay,
    BitField,
    BitFieldArray,
    Boolean,
    Comparer,
    Counter,
    CReg,
    Divider,
    NodeFrequencyToPeriodConverter,
    HSVtoRGBConvertor,
    IEEE754FP32,
    LED,
    ManualPulse,
    Multiplexer,
    Multiplier,
    NodeStringInput,
    NumericInput,
    Oscillator,
    RandomGen,
    SimplePinLogger,
    SineWaveGen,
    Switch,
    WavePlotter,
    NodeEnumSelector,
    NodeMathMap,
    NodeStringFormat,
    NodeUnixTimestamp,
    // conversion
    NodeImageBase64ToBlobConvertor,
    // logic (plc)
    NodePLC,
    // tb
    NodeTBAdapter,
    NodeStateDecoder,
    // organization
    NodeCableTieSingle,
    NodeRunDispatch,
    // prototyping
    NodeSubCanvasPrototype,
    // data structures
    NodeQueue,
    // tests
    NodeT2I,
    NodeI2IUpscale,
    NodePromptTranspiler,
    NodeGauge,
    // objects
    NodeObjectToJSON,
    NodeJSONToObject,
    NodeObjectAccessor,
    NodeArrayAccessor,
    // visualization
    NodeImageDisplay,
    NodeDashboard,
    NodeAdvancedSevenSegmentDisplay,
    // files
    NodeSimpleFileDownload,
    // orchestration
    ProgrammableLogicController,

    ...simTestNodes,
    ...experimentalNodes
];

