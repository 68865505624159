import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";

type ArithmeticComparerOperationResult = {
    comparisonResult: boolean,
    dynamicComparisonResult?: any // TODO: Rename...
}

type ArithmeticComparerOperation = {
    opcode: number,
    op: (a: number, b: number) => ArithmeticComparerOperationResult
    symbol: string,
    isUsingTristate?: boolean
}

const arithmeticComparerOperations: Array<ArithmeticComparerOperation> = [
    {
        opcode: 0,
        symbol: "=",
        op: (a, b) => ({
            comparisonResult: a === b
        })
    },
    {
        opcode: 1,
        symbol: "!=",
        op: (a, b) => ({
            comparisonResult: a !== b
        })
    },
    {
        opcode: 2,
        symbol: ">",
        op: (a, b) => ({
            comparisonResult: a > b
        })
    },
    {
        opcode: 3,
        symbol: "<",
        op: (a, b) => ({
            comparisonResult: a < b
        })
    },
    {
        opcode: 4,
        symbol: ">=",
        op: (a, b) => ({
            comparisonResult: a >= b
        })
    },
    {
        opcode: 5,
        symbol: "<=",
        op: (a, b) => ({
            comparisonResult: a <= b
        })
    },
    {
        // Spaceship operator
        opcode: 6,
        symbol: "<=>",
        isUsingTristate: true,
        op: (a, b) => ({
            comparisonResult: a == b,
            dynamicComparisonResult: a == b ? 0 : (
                a > b ? -1 : 1 // TODO: Check if logic adds up
            ),
        })
    },
];

// noinspection SpellCheckingInspection
export const ArithmeticComparer: NodeSetupInfo = {
    label: "Arithmetic Comparer",
    classname: "arithmetic.comparer",
    parameterConfig: [],
    factory: parameters => new Node<{
        op?: ArithmeticComparerOperation
    }>({
        id: v4(),
        classname: "arithmetic.comparer",
        label: "arith. comp.",
        defInPins: ["a", "b"],
        defOutPins: ["res"],
        state: {
            // op: arithmeticComparerOperations[0]
        },
        init() {
            const node = this;
            const resPort = this.pins.out("res");
            const tristatePort = this.pins.out("tri");
            const aPort = this.pins.in("a");
            const bPort = this.pins.in("b");

            tristatePort.statusRegister.defaults.deactivated(true);

            const performOperation = () => {
                const a = Number(aPort.lastReadState);
                const b = Number(bPort.lastReadState);
                const op = node.state!.state.op;
                if (op === undefined) return;
                const res = op.op(a, b);
                const resPortVal = res.comparisonResult;
                resPort.write(resPortVal);
                if (op.isUsingTristate) {
                    const tristatePortVal = res.dynamicComparisonResult;
                    tristatePort.write(tristatePortVal);
                }
            }

            ["a", "b"].forEach(pinName => {
                node.pins.in(pinName).attach({
                    read: performOperation
                });
            });

            // Init OP code selector pin
            const opCodeInPort = this.pins.in("op");
            opCodeInPort.attach({
                read(opcode: number) {
                    opcode = Number(opcode);

                    node.state.update(prevState => {
                        let op = arithmeticComparerOperations.find(ariOP => ariOP.opcode === opcode);
                        if (op === undefined) {
                            // TODO: Set error state
                            // op = arithmeticComparerOperations[0];
                            tristatePort.statusRegister.defaults.deactivated(true);
                            opCodeInPort.statusRegister.defaults.error(true);
                            return { op: undefined };
                        } else {
                            opCodeInPort.statusRegister.defaults.error(false);
                        }

                        const requireTristatePort = op?.isUsingTristate ?? false;
                        tristatePort.statusRegister.defaults.deactivated(!requireTristatePort);

                        return { op };
                    });
                    performOperation();
                }
            })
        },
        customRenderer: node => (
            <div style={{ width: 22 }} children={
                <DescriptiveTypography
                    text={node.state.state.op?.symbol ?? DefaultCharacterSymbols.placeholder}
                    style={{ whiteSpace: "pre", textAlign: "center" }}
                />
            }/>
        )
    })
}
