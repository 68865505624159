import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";
import {Pin} from "../../../backend/Pin";
import {NodeImageBufferClass} from "./NodeImageBuffer";

export let NodeImageGrayscale: NodeSetupInfo = {
    label: "grayscale",
    classname: "image.pipeline.grayscale",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "image.pipeline.grayscale",
        label: "grayscale",
        defOutPins: [DefaultCharacterSymbols.clockPinKey],
        defInPins: [DefaultCharacterSymbols.clockPinKey, "#buf"],
        init: function () {
            this.createInternalWire({
                targetInPin: DefaultCharacterSymbols.clockPinKey,
                targetOutPin: DefaultCharacterSymbols.clockPinKey,
                transformer: clockSignal => {
                    const bufInstancePin: Pin<any, NodeImageBufferClass> = this.pins.in("#buf").inputConnections[0]
                    const bufferNode = bufInstancePin.value!;
                    const imageData = bufferNode.raw.value!;
                    const buf = imageData.data;

                    for (let y = 0; y != imageData.height; y++) {
                        for (let x = 0; x != imageData.width; x++) {
                            let i = (y * imageData.width + x) * 4;
                            const intensity = (buf[i] + buf[i + 1] + buf[i + 2]) / 3;
                            buf[i    ] = intensity;
                            buf[i + 1] = intensity;
                            buf[i + 2] = intensity;
                        }
                    }

                    return clockSignal;
                }
            })
        }
    })
}
