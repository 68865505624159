import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import React, {FC} from "react";
import {ButtonGroup} from "../../../../ardai/components/ButtonGroup";
import {Tag} from "../../../../ardai/components/Tag";
import {CloudOutlined, DeleteRounded, DownloadRounded, FullscreenRounded, SettingsRounded} from "@mui/icons-material";
import fileDownload from "js-file-download";
import axios from "axios";
import {ButtonModalCompound} from "../../../../ardai/components/ButtonModalCompound";
import {useTriton} from "../../../../triton/TritonHooks";
import InfiniteViewer from "react-infinite-viewer";
import {useAdvancedState, useStaticState} from "../../../../ardai/hooks/StaticStateHook";
import {AnimatePresence, motion} from "framer-motion";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {Triton} from "../../../../triton/Triton";

// TODO: Rename
enum OperatorMode {
    IN, OUT, INOUT
}

export type NodeImageDisplayState = {
    displaySize: number,
    imageBase64?: string,
    operatorMode?: OperatorMode
}

export const NodeImageDisplay: NodeSetupInfo = {
    label: "NodeImageDisplay",
    classname: "visualization.img",
    parameterConfig: [],
    factory: parameters => new Node<NodeImageDisplayState>({
        id: v4(),
        classname: "visualization.img",
        label: "img",
        state: {
            displaySize: 100,
            imageBase64: undefined
        },
        init: node => {
            const s = node.state.state;
            const mode = s.operatorMode
                ?? parameters.get("operator-mode")
                ?? OperatorMode.INOUT as OperatorMode;
            const hasOutput = mode > 0;
            const hasInput = mode !== 1;

            const pushImageToOutput = () => {
                node.pins.out("img").write(node.state.state.imageBase64);
            }

            const pushImageToOutputWithGuard = () => {
                if (!hasOutput) return;
                pushImageToOutput();
            }

            if (hasInput) {
                node.pins.in("img").attachOnRead(base64 => {
                    node.state.update({
                        imageBase64: base64
                    });
                    pushImageToOutputWithGuard();
                });
            }

            if (hasOutput) {
                node.pins.out("img")
            }
        },
        customRenderer: node => {
            return (
                <NodeImageDisplayComponent node={node}/>
            );
        }
    })
}

const StyledNodeImageDisplayComponent = styled.div<{
    t: Triton
}>`
    .dropzone {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: 1px dashed ${p => p.t.col("bg_modal")};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: border .025s;
        cursor: pointer;

        &:hover {
            border: 1px dashed ${p => p.t.col("color_primary")};
        }
        
        * {
            cursor: pointer;    
        }
    }
`;

type NodeImageDisplayComponentState = {
    enableAdvancedView: boolean
}

const NodeImageDisplayComponent: FC<{
    node: Node<NodeImageDisplayState>
}> = props => {
    const { node } = props;
    const s = node.state.state;
    const t = useTriton();

    // const imgBase64 = node.pins.in("img").lastReadState;
    const imgBase64 = node.state.state.imageBase64;
    // const imgDataUrl = `data:image/png;base64,${imgBase64}`;
    const imgDataUrl = imgBase64;

    const [state, ctx] = useAdvancedState<NodeImageDisplayComponentState>({
        initial: {
            enableAdvancedView: false
        }
    }).stateWithCtx;

    const hasImage = imgBase64 !== undefined;

    /**
     * This is to be triggered whenever a new image was set.
     * Important: Not when image was deleted aka. state: any -> undefined
     */
    const onNewImageSetManually = () => {
        if (s.operatorMode !== OperatorMode.OUT) {
            const newImage = node.state.state.imageBase64;
            node.pins.out("img").write(newImage);
        }
    }

    return (
        <StyledNodeImageDisplayComponent t={t} style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            alignItems: "center",
            padding: "8px 0"
        }}>
            {/* image display */}
            <div style={{
                width: node.state.state.displaySize ?? 100,
                aspectRatio: "1 / 1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>

                { hasImage && (
                    <img
                        style={{
                            height: "auto",
                            // width: "auto",
                            width: "100%",
                            maxHeight: "100%",
                            maxWidth: "100%",
                            borderRadius: 8
                        }}
                        alt={"stable diffusion result"}
                        src={imgDataUrl}
                    />
                ) || (
                    <Dropzone maxFiles={1} onDrop={async (acceptedFiles, fileRejections, event) => {
                        const imgFile = acceptedFiles[0];

                        const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result as string);
                            reader.onerror = reject;
                        });

                        let base64 = await toBase64(imgFile);
                        // Remove the "data:image/png;base64," part from the data url
                        // base64 = base64.split(",")[1];

                        node.state.update({
                            imageBase64: base64
                        });

                        onNewImageSetManually();
                    }} children={dropzoneState => (
                        <div {...dropzoneState.getRootProps({ className: 'dropzone' })}>
                            <input {...dropzoneState.getInputProps()} type={"file"}/>

                            <DescriptiveTypography text={"Files go brr"} noSelect/>
                        </div>
                    )}/>
                )}


            </div>

            {/* toolbar & information */}
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: 4
            }}>
                <ButtonModalCompound
                    borderless
                    button={<Tag tag={<FullscreenRounded sx={{fontSize: 14}}/>}/>}
                    modalContent={ctx => (
                        <div style={{
                            backgroundColor: t.col("bg_main"),
                            height: "100vh",
                            width: "100vw",
                            display: "grid",
                            gridTemplateRows: "min-content auto",
                            gap: 8
                        }}>
                            <div style={{
                                height: "60px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 8
                            }}>

                            </div>
                            <div style={{
                                height: "100%",
                                display: "grid",
                                overflow: "hidden"
                            }}>
                                <InfiniteViewer
                                    rangeX={[-1e3, 1e3]}
                                    rangeY={[-1e3, 1e3]}
                                    useAutoZoom={true}
                                    zoomRange={[.75, 5]}
                                    useTransform={true}
                                    useWheelPinch={true}
                                    preventWheelClick={true}
                                    useMouseDrag={true}
                                    useWheelScroll={true}
                                >
                                    <div style={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden"
                                    }}>
                                        <img
                                            style={{
                                                height: "100%",
                                                width: "auto",
                                                // width: "100%",
                                                maxHeight: "100%",
                                                maxWidth: "100%",
                                                // imageRendering: "pixelated"
                                            }}
                                            src={imgBase64}
                                        />
                                    </div>
                                </InfiniteViewer>
                            </div>
                        </div>
                    )}
                />

                <ButtonGroup>
                    <Tag tag={<DownloadRounded sx={{ fontSize: 14 }}/>} onClick={async () => {
                        const dataToBlob = async (imageData: string) => {
                            return await (await fetch(imageData)).blob();
                        };
                        const blob = await dataToBlob(`data:image/png;base64,${node.pins.in("img").lastReadState}`);
                        fileDownload(blob, "image.png")
                    }}/>
                    <Tag
                        active={state.enableAdvancedView}
                        applyActiveScaling
                        tag={
                            <SettingsRounded sx={{
                                fontSize: 14
                            }}/>
                        }
                        onClick={() => ctx.reverseBool("enableAdvancedView")}
                    />
                </ButtonGroup>
            </div>

            { state.enableAdvancedView && (
                <motion.div
                    style={{
                        width: "100%",
                    }}
                >
                    <div style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        flexWrap: "wrap",
                        gap: 4
                    }}>
                        <ButtonGroup>
                            <Tag tag={"S"} onClick={() => node.state.update(prevState => ({
                                displaySize: 100
                            }))}/>
                            <Tag tag={"M"} onClick={() => node.state.update(prevState => ({
                                displaySize: 200
                            }))}/>
                            <Tag tag={"L"} onClick={() => node.state.update(prevState => ({
                                displaySize: 300
                            }))}/>
                        </ButtonGroup>

                        <Tag tag={<DeleteRounded sx={{ fontSize: 12 }}/>} onClick={() => {
                            node.state.update({
                                imageBase64: undefined
                            })
                        }}/>
                    </div>
                </motion.div>
            ) }
        </StyledNodeImageDisplayComponent>
    );
}
