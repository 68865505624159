import React, {FC, ReactNode} from "react";
import {IndicatorContainer} from "./IndicatorContainer";
import {IndicatorIcon} from "./IndicatorIcon";
import {IndicatorUtils} from "./utils/IndicatorUtils";

export type FusePanelElement = {
    connected: boolean,
    icon: ReactNode,
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
    label?: string,
}

export const IndicatorFusePanel: FC<{
    fuses: Array<FusePanelElement>,
    columns?: number
}> = props => {
    return (
        <IndicatorContainer columns={props.columns} children={
            props.fuses.map((fuse, idx) => {
                return (
                    <IndicatorIcon
                        key={idx}
                        onClick={fuse.onClick}
                        mappingKey={fuse.connected ? 1 : 0}
                        colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => {
                            mapping.eq(1, "#21262d")
                            mapping.eq(0, "#ffdf60")
                        })}
                        iconMapping={new IndicatorUtils.NumericMapping<React.ReactNode>(mapping => {
                            mapping.fallback(0, fuse.icon)
                        })}
                    />
                );
            })
        }/>
    );
}
