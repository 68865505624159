export enum NodeEventTypes {
    PIN_READ = "PIN_READ",
    PIN_WRITE = "PIN_WRITE",

    PIN_VISUALS_UPDATED = "PIN_VISUALS_UPDATED",
    PIN_DEFINITION_UPDATED = "PIN_DEFINITION_UPDATED",
    PIN_STATE_UPDATED = "PIN_STATE_UPDATED",

    NODE_DEFINITION_UPDATED = "NODE_DEFINITION_UPDATE",
    GRAPH_UPDATE = "GRAPH_UPDATE"
}

