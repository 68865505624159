import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";
import {FC, useEffect} from "react";
import {Tag} from "../../../../ardai/components/Tag";
import {IndicatorFusePanel} from "../../components/indicators/IndicatorFusePanel";
import {Icon} from "../../../../base/components/base/Icon";
import {BlockRounded, SubdirectoryArrowRightRounded} from "@mui/icons-material";

export type NodePipelineStepControllerState = {
    relay: boolean,
    suspend: boolean,
}

export let NodePipelineStepController: NodeSetupInfo = {
    label: "step controller",
    classname: "image.pipeline.step-controller",
    parameterConfig: [],
    factory: parameters => new Node<NodePipelineStepControllerState>({
        id: v4(),
        // description: "pipeline step controller",
        classname: "image.pipeline.step-controller",
        label: "psc",
        defOutPins: ["-", DefaultCharacterSymbols.clockPinKey],
        defInPins: ["-"],
        state: {
            relay: true,
            suspend: false
        },
        init: function () {
            this.pins.in("-").attachOnRead(pipelineClockSignal => {
                const s = this.state.state;
                if (!s.suspend) {
                    this.pins.out(DefaultCharacterSymbols.clockPinKey).write(pipelineClockSignal);
                }
                if (s.relay) {
                    this.pins.out("-").write(pipelineClockSignal);
                }
            })
        },
        customRenderer: node => (
            <NodePipelineStepControllerDisplay node={node}/>
        )
    })
}

export const NodePipelineStepControllerDisplay: FC<{
    node: Node<NodePipelineStepControllerState>
}> = props => {
    const state = props.node.state;
    const cur = state.state;

    useEffect(() => {
        props.node.pins.out(DefaultCharacterSymbols.clockPinKey).statusRegister.defaults.deactivated(cur.suspend)
    }, [cur.suspend]);

    useEffect(() => {
        props.node.pins.out("-").statusRegister.defaults.deactivated(!cur.relay)
    }, [cur.relay]);

    return (
        <div style={{
            display: "grid",
            gridTemplateRows: "repeat(3, min-content)",
            gap: 4,
            padding: "8px 0"
        }}>
            {/*
            <Tag
                tag={"relay"}
                active={cur.relay ?? false}
                onClick={() => state.reverseBool("relay")}
            />
            <Tag
                tag={"suspend"}
                active={cur.suspend ?? false}
                onClick={() => state.reverseBool("suspend")}
            />
            */}

            <IndicatorFusePanel fuses={[
                {
                    onClick: () => state.reverseBool("suspend"),
                    label: "suspend",
                    icon: <SubdirectoryArrowRightRounded/>,
                    connected: !(cur.suspend ?? false)
                },
                {
                    onClick: () => state.reverseBool("relay"),
                    label: "relay",
                    icon: <BlockRounded/>,
                    connected: cur.relay ?? false
                }
            ]}/>
        </div>
    );
}
