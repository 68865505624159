import React, {CSSProperties, HTMLAttributes} from "react";
import styled from "styled-components";

const StyledDescriptiveTypography = styled.span<{
    props: DescriptiveTypographyProps
}>`
    font-size: 12px;
    color: rgb(139, 148, 158);
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    line-height: 1.5;
    word-wrap: break-word;
    text-decoration: none;
    
    &:hover {
        ${p => p.props.enableUnderlineOnHover ? 'text-decoration: underline;' : ''}
    }
`;

export type DescriptiveTypographyProps = {
    text?: React.ReactNode,
    style?: CSSProperties,
    // color?: string
    baseProps?: HTMLAttributes<any>,
    noSelect?: boolean
    whiteSpaceNoWrap?: boolean,
    enableUnderlineOnHover?: boolean,
}

export const DescriptiveTypography: React.FC<DescriptiveTypographyProps> = props => {
    return (
        <StyledDescriptiveTypography
            props={props}
            className={"triton-typography-secondary"}
            children={props.text}
            {...props.baseProps}
            style={{
                userSelect: props.noSelect ? "none" : "auto",
                whiteSpace: props.whiteSpaceNoWrap ? "nowrap" : undefined,
                // color: props.color ?? undefined,
                ...props.style,
            }}
        />
    );
}
