import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const NodeArrayAccessor: NodeSetupInfo = {
    label: "NodeArrayAccessor",
    classname: "objects.array-accessor",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "objects.array-accessor",
        label: "at idx",
        defOutPins: ["e"],
        defInPins: ["[]", "i"],
        init: function () {

            const doAtIdxOp = () => {
                const idxPin = this.pins.in("i");
                let idx = idxPin.lastReadState;
                idx = Number(idx);
                // noinspection UnnecessaryLocalVariableJS
                const arr = this.pins.in("[]").lastReadState;

                // if (idx > arr.length - 1) {
                //     idxPin.status.defaults.error(true)
                // } else if (idxPin.status.defaults.isError) {
                //     idxPin.status.defaults.error(false)
                // }

                const e = arr[idx];
                this.pins.out("e").write(e);

            }

            ["[]", "i"].forEach(pinId => {
                this.pins.in(pinId).attachOnRead(doAtIdxOp);
            })

            /**
             * in("i").setReadTransformer(i => Number(i))
             * -> in("i").setReadTransformer(toNumber)
             *
             * in("i").attachOnReadGuard((i, ctx) => {
             *     ctx.check(i > 10)
             *     ctx.check(i < arr.length)
             *
             *     ctx.check(i === 42).fail("")
             *
             *     fn check := (bool) -> CheckAction
             *
             *     class CheckAction :=
             *         fail(reason) -> onRead won't fire and a red highlight will appear
             *         warn(reason) -> don't break the execution cycle, but highlight will appear
             * })
             */

            // this.createInternalWire({
            //     targetInPin: "[]",
            //     targetOutPin: "e",
            //     transformer: arr => {
            //         let idx = this.pins.in("i").lastReadState;
            //         idx = Number(idx);
            //         return arr[idx];
            //     }
            // })
        }
    })
}
