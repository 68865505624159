import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {FC, useEffect} from "react";
import {ButtonGroup} from "../../../../ardai/components/ButtonGroup";
import {Tag} from "../../../../ardai/components/Tag";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {RefreshRounded} from "@mui/icons-material";

export type NodeDecimalShifterState = {
    baseFactor: number,
    shift: number,
}

export const NodeDecimalShifter: NodeSetupInfo = {
    label: "NodeDecimalShifter",
    classname: "arithmetic.decimal-shifter",
    parameterConfig: [],
    factory: parameters => new Node<NodeDecimalShifterState>({
        id: v4(),
        classname: "arithmetic.decimal-shifter",
        label: "dec. shifter",
        state: {
            baseFactor: 1,
            shift: 0
        },
        defInPins: ["i"],
        defOutPins: ["o"],
        init: function () {
            this.pins.in("i").attachOnRead(i => {
                i = Number(i);
                const s = this.state.state;
                const factor = s.baseFactor * Math.pow(10, s.shift);
                const o = i * factor;
                this.pins.out("o").write(o);
            })
        },
        customRenderer: node => (
            <NodeDecimalShifterDisplay node={node}/>
        )
    })
}

const NodeDecimalShifterDisplay: FC<{
    node: Node<NodeDecimalShifterState>
}> = props => {
    const node = props.node;
    const s = node.state.state;

    useEffect(() => {
        const i = node.pins.in("i");
        if (i.lastReadState === undefined) return;
        i.onRead(i.lastReadState);
    }, [s.shift, s.baseFactor]);

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 8
        }}>
            <div style={{
                width: 50,
                overflowX: "scroll"
            }}>
                <DescriptiveTypography text={`e${s.shift}`}/>
            </div>
            <ButtonGroup orientation={"horizontal"}>
                <Tag tag={"-"} applyActiveScaling onClick={() => {
                    node.state.update(prevState => ({
                        shift: prevState.shift - 1
                    }));
                }}/>
                <Tag tag={"+"} applyActiveScaling onClick={() => {
                    node.state.update(prevState => ({
                        shift: prevState.shift + 1
                    }));
                }}/>
                <Tag tag={<RefreshRounded sx={{ fontSize: 12 }}/>} disabled={s.shift === 0} applyActiveScaling onClick={() => {
                    node.state.update(prevState => ({
                        shift: 0
                    }));
                }}/>
            </ButtonGroup>
        </div>
    );
}
