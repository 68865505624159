export type Token = {
    kind: StdTokenKinds | string,
    spelling: string,
    annotations: Set<StdTokenAnnotations | string>
}

export namespace Token {
    export const newToken = (token: Partial<Token> = {}): Token => {
        return {
            kind: "digit",
            annotations: new Set,
            spelling: "0",
            ...token
        }
    }
}

type StdTokenKinds = "digit" | "symbol";

type StdTokenAnnotations = "dot" | "comma";
