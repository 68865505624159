import {Pixel} from "./data/Pixel";
import {Node} from "../../../backend/Node";
import {Pin} from "../../../backend/Pin";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";
import {NodeImageBufferClass} from "./NodeImageBuffer";

export type NodeImageOverlayState<PixelType> = {
    strength: number
}

export const NodeImageOverlay = {
    label: "NodeImageOverlay",
    classname: "images.overlay",
    parameterConfig: [],
    factory: () => new NodeImageOverlayClass
}

export class NodeImageOverlayClass<PixelType = Pixel> extends Node<NodeImageOverlayState<PixelType>> {

    constructor() {
        super({
            label: "overlay",
            defInPins: [DefaultCharacterSymbols.clockPinKey, "#buf_main", "#buf_sub", "strength"],
            state: {
                strength: .5
            }
        });
    }

    strength = this.pins.in("strength").attachOnRead(strength => {
        strength = Number(strength);
        strength = Math.max(Math.min(strength, 1), 0);
        this.state.update({
            strength
        });
    });

    init() {
        super.init();
        this.createInternalWire({
            targetInPin: DefaultCharacterSymbols.clockPinKey,
            targetOutPin: DefaultCharacterSymbols.clockPinKey,
            transformer: clockSignal => {
                this.overlayMainWithSub();
                return clockSignal;
            }
        })
    }

    private overlayMainWithSub() {
        const bufMainInstancePin: Pin<any, NodeImageBufferClass> = this.pins.in("#buf_main").inputConnections[0];
        const bufferMainNode = bufMainInstancePin.value!;
        const mainImageData = bufferMainNode.raw.value!;
        const bufMain = mainImageData.data;

        const bufSubInstancePin: Pin<any, NodeImageBufferClass> = this.pins.in("#buf_sub").inputConnections[0];
        const bufferSubNode = bufSubInstancePin.value!;
        const subImageData = bufferSubNode.raw.value!;
        const bufSub = subImageData.data;

        const height = mainImageData.height;
        const width = mainImageData.width;

        const subStrength = this.state.state.strength ?? .5;
        const mainStrength = 1 - subStrength;
        for (let y = 0; y != height; y++) {
            for (let x = 0; x != width; x++) {
                let i = (y * width + x) * 4;
                bufMain[i    ] = mainStrength * bufMain[i    ] + subStrength * bufSub[i    ]; // r
                bufMain[i + 1] = mainStrength * bufMain[i + 1] + subStrength * bufSub[i + 1]; // g
                bufMain[i + 2] = mainStrength * bufMain[i + 2] + subStrength * bufSub[i + 2]; // b
                bufMain[i + 3] = mainStrength * bufMain[i + 3] + subStrength * bufSub[i + 3]; // a
            }
        }
    }
}
