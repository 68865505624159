import {FC, useEffect, useRef} from "react";
import {IStaticStateCtx, useAdvancedState} from "../../../../../../ardai/hooks/StaticStateHook";
import {DescriptiveTypography} from "../../../../../../triton/components/typography/DescriptiveTypography";

export type IndicatorPanelLine = {
    key: string,
    display: string
}

export interface IIndicatorPanelLineContext {
    get data(): IndicatorPanelLine
    get key(): string
    delete(): void
}

export interface IIndicatorPanelContext {
    line(key: string): IIndicatorPanelLineContext
    add(key: string, display: string | undefined): IIndicatorPanelLineContext
    delete(key: string): void
}

export type IndicatorPanelProps = {
    onInit: (ctx: IIndicatorPanelContext) => void
}

export type IndicatorPanelState = {
    lines: Array<IndicatorPanelLine>
}

class IndicatorPanelLineContext implements IIndicatorPanelLineContext {
    constructor(
        private readonly host: IndicatorPanelContext,
        private readonly resolve: () => IndicatorPanelLine,
        private readonly update: (patches: Partial<IndicatorPanelLine>) => void,
    ) {}

    get data(): IndicatorPanelLine {
        return this.resolve();
    }

    get key(): string {
        return this.resolve().key;
    }

    delete(): void {
        const key = this.key;
        this.host.hostStateContext.update(prevState => ({
            ...prevState,
            lines: prevState.lines.filter(line => line.key !== key)
        }))
    }
}

class IndicatorPanelContext implements IIndicatorPanelContext {
    constructor(
        public hostStateContext: IStaticStateCtx<IndicatorPanelState>
    ) {}

    private has(key: string): boolean {
        return this.hostStateContext.state.lines.find(line => line.key === key) !== undefined;
    }

    add(key: string, display: string | undefined = undefined): IIndicatorPanelLineContext {
        addLogic: {
            if (this.has(key)) break addLogic;
            this.hostStateContext.update(prevState => {
                return {
                    lines: [...prevState.lines, {
                        key,
                        display: display ?? ""
                    }]
                }
            });
        }

        return this.line(key);
    }

    line(key: string): IIndicatorPanelLineContext {
        const resolver = this.newLineResolver(key);
        return new IndicatorPanelLineContext(this, this.newLineResolver(key), patches => {
            this.hostStateContext.update(prevState => {
                const original = resolver();
                const idx = prevState.lines.indexOf(original);

                prevState.lines[idx] = {
                    ...resolver(),
                    ...patches
                };

                return {
                    lines: [...prevState.lines]
                }
            })
        });
    }

    delete(key: string) {
        this.hostStateContext.update(prevState => ({
            ...prevState,
            lines: prevState.lines.filter(line => line.key !== key)
        }))
    }

    private newLineResolver(key: string): () => IndicatorPanelLine {
        return () => {
            const line = this.hostStateContext.state.lines.find(line => line.key === key);
            if (line === undefined) throw new Error("line is undefined"); // TODO: Make better...
            return line;
        }
    }
}

export const IndicatorPanel: FC<IndicatorPanelProps> = props => {
    const [state, ctx] = useAdvancedState<IndicatorPanelState>({
        initial: {
            lines: []
        }
    }).stateWithCtx;

    const panelContextRef = useRef(new IndicatorPanelContext(ctx));

    useEffect(() => {
        props.onInit(panelContextRef.current);
    }, []);

    useEffect(() => {
        panelContextRef.current.hostStateContext = ctx;
    }, [state]);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
        }}>
            { state.lines.map(line => {
                return (
                    <div key={line.key}>
                        <DescriptiveTypography text={line.display}/>
                    </div>
                );
            }) }
        </div>
    );
}
