import {NodeSetupInfo} from "../../../NodeSetupInfo";
import {Node} from "../../../../backend/Node";
import {v4} from "uuid";
import {createContext, CSSProperties, FC, useContext, useEffect} from "react";
import styled from "styled-components";
import {motion, useMotionValue, useSpring} from "framer-motion";
import {useTriton} from "../../../../../triton/TritonHooks";
import {DescriptiveTypography} from "../../../../../triton/components/typography/DescriptiveTypography";
import {Interpolate} from "../../../../../triton/components/advanced/Interpolate";
import {useForceRenderFunc} from "../../../../../ForceRerenderHook";
import {createBridge, ReactBackend, ReactMaster} from "../../../../../ReactAPIBridgeUtils";

export const NodeGauge: NodeSetupInfo = {
    label: "NodeGauge",
    classname: "sim.gauge",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "sim.gauge",
        label: "gauge",
        defInPins: ["%"],
        state: {
            enableMarkerModule: true,
            showMarkedAreas: true,
            showThresholdMarkers: true,
            showValue: true,
            height: 200,
            centralModuleWidth: 50
        },
        customRenderer: node => (
            <div style={{
                padding: "8px 0"
            }}>
                <GaugeDisplay
                    actual={Number(node.pins.in("%").lastReadState ?? 0)}
                    modules={{
                        enableMarkerModule: node.state.state.enableMarkerModule
                    }}
                    visuals={{
                        showMarkedAreas: node.state.state.showMarkedAreas,
                        showThresholdMarkers: node.state.state.showThresholdMarkers,
                        showValue: node.state.state.showValue,
                        height: node.state.state.height,
                        centralModuleWidth: node.state.state.centralModuleWidth,
                    }}
                />
            </div>
        )
    })
}

const StyledGaugeDisplay = styled.div<{
    visuals: GaugeDisplayVisualsConfig
}>`
  position: relative;
  display: flex;
  
  // width: 50px;
  height: ${p => p.visuals.height ?? 200}px;
  // padding: 8px 0;
  overflow: hidden;
`;

export type GaugeDisplayModulesConfig = {
    enableMarkerModule: boolean,
    enableCentralModule: boolean
}

export type GaugeDisplayVisualsConfig = {
    showMarkedAreas: boolean,
    showThresholdMarkers: boolean,
    showValue: boolean,
    height: number,
    centralModuleWidth: number
}

const genDefaultGaugeDisplayModulesConfig = (): GaugeDisplayModulesConfig => ({
    enableMarkerModule: false,
    enableCentralModule: true
});

const genDefaultGaugeDisplayVisualsConfig = (): GaugeDisplayVisualsConfig => ({
    showMarkedAreas: true,
    showThresholdMarkers: true,
    showValue: true,
    height: 200,
    centralModuleWidth: 50
});

export type GaugeDisplayProps = {
    actual: number,
    visuals?: Partial<GaugeDisplayVisualsConfig>,
    modules?: Partial<GaugeDisplayModulesConfig>,
    areas?: Array<MarkedArea>
}

export const GaugeDisplay: FC<GaugeDisplayProps> = props => {

    const visualsConfig: GaugeDisplayVisualsConfig = {
        ...genDefaultGaugeDisplayVisualsConfig(),
        ...props.visuals
    }

    const moduleConfig: GaugeDisplayModulesConfig = {
        ...genDefaultGaugeDisplayModulesConfig(),
        ...props.modules
    }

    return (
        <GaugeDisplayStaticConfigContext.Provider value={{
            modules: moduleConfig,
            visuals: visualsConfig
        }}>
            <StyledGaugeDisplay visuals={visualsConfig}>
                { moduleConfig.enableMarkerModule && (
                    <GaugeMarkerDisplay {...props}/>
                ) }
                { moduleConfig.enableCentralModule && (
                    <GaugeCentralDisplay {...props}/>
                ) }
            </StyledGaugeDisplay>
        </GaugeDisplayStaticConfigContext.Provider>
    );
}

type GaugeDisplayStaticConfig = {
    visuals: GaugeDisplayVisualsConfig,
    modules: GaugeDisplayModulesConfig
}

const GaugeDisplayStaticConfigContext = createContext<GaugeDisplayStaticConfig>({
    modules: genDefaultGaugeDisplayModulesConfig(),
    visuals: genDefaultGaugeDisplayVisualsConfig()
})

const StyledGaugeCentralDisplay = styled.div<{
    visuals: GaugeDisplayVisualsConfig
}>`
  position: relative;
  min-width: ${p => p.visuals.centralModuleWidth ?? 50}px;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

const GaugeCentralDisplay: FC<GaugeDisplayProps> = props => {
    const config = useContext(GaugeDisplayStaticConfigContext);
    const visualConfig = config.visuals;
    const t = useTriton();
    let actualValuePercentage = props.actual ?? 0;
    if (actualValuePercentage === Number.NaN) actualValuePercentage = 0;

    const actualValuePercentageMotionVal = useMotionValue(actualValuePercentage);
    const actualValuePercentageMotionValSmooth = useSpring(actualValuePercentageMotionVal, {
        // mass: 100,
        // bounce: 0,
        damping: 50, // 50
        stiffness: 1000, // 400
        bounce: 0
    });

    actualValuePercentageMotionVal.set(actualValuePercentage, true);

    const actualValuePercentageSmooth = Number(actualValuePercentageMotionValSmooth.get());

    const forceRerender = useForceRenderFunc();

    useEffect(() => {
        const unsubscribe = actualValuePercentageMotionValSmooth.on("change", () => {
            forceRerender();
        });

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <StyledGaugeCentralDisplay visuals={visualConfig}>
            <div style={{
                padding: "16px 4px",
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    { visualConfig.showValue && (
                        <Interpolate value={actualValuePercentage}/>
                    ) }
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    {/*
                    <Interpolate value={actualValuePercentage}/>
                    */}
                </div>
            </div>

            {/* area markers */}
            { visualConfig.showMarkedAreas && (
                <div>
                    { props.areas?.map((area, idx) => (
                        <GaugeMarkedArea
                            key={idx}
                            actual={actualValuePercentage}
                            end={area.end}
                            start={area.start}
                            withinAreaColor={area.withinAreaColor}
                            outsideAreaColor={area.outsideAreaColor}
                            bevelMode={area.bevelMode}
                            border={area.border}
                        />
                    )) }

                    {/*
                    <GaugeMarkedArea
                        actual={actualValuePercentage}
                        end={Number.MAX_VALUE}
                        start={90}
                        withinAreaColor={"crimson"}
                        outsideAreaColor={"#ed143d80"}
                        bevelMode={"top"}
                    />
                    <GaugeMarkedArea
                        actual={actualValuePercentage}
                        end={89}
                        start={70}
                        withinAreaColor={"#ffbd29"}
                        outsideAreaColor={t.colObj("fg_muted").withAlpha(.5).css()}
                    />
                    <GaugeMarkedArea
                        actual={actualValuePercentage}
                        end={10}
                        start={0}
                        withinAreaColor={"#0ea1e3"}
                        outsideAreaColor={t.colObj("fg_muted").withAlpha(.5).css()}
                        bevelMode={"bottom"}
                    />
                    */}
                </div>
            ) }

            {/* marker for save max value */}
            { visualConfig.showThresholdMarkers && (
                <div>
                    <motion.div
                        style={{
                            zIndex: 5,
                            width: "100%",
                            position: "absolute",
                            height: 5,
                            left: 0,
                            display: "grid"
                            // border: `1px dotted ${t.col("color_primary")}`,
                        }}
                        initial={{
                            bottom: "0%"
                        }}
                        animate={{
                            bottom: `${60}%`,
                        }}
                    >
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            color: `${t.col("color_secondary")}`
                        }}>
                            <div style={{
                                width: 7,
                                height: 5,
                                border: `1px solid currentcolor`,
                            }}/>
                            <div style={{
                                width: 7,
                                height: "100%",
                                border: `1px solid currentcolor`,
                            }}/>

                            <div style={{
                                width: "100%",
                                height: 1,
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                opacity: .4,
                                borderBottom: `1px dotted currentcolor`,
                            }}/>
                        </div>
                    </motion.div>
                </div>
            ) }

            {/* actual value slider (smoothed) */}
            <motion.div
                style={{
                    width: "100%",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    borderTop: `1px dotted ${t.col("color_primary")}`,
                }}
                animate={{
                    height: `${actualValuePercentageSmooth}%`,
                }}
            >
                <div style={{
                    position: "absolute",
                    zIndex: 10,
                    width: "100%",
                    backgroundColor: t.col("color_primary"),
                    opacity: .05,
                    height: "100%"
                }}/>
            </motion.div>

        </StyledGaugeCentralDisplay>
    );
}

const StyledGaugeMarkerDisplay = styled.div`
  min-width: 20px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GaugeMarkerDisplay: FC<GaugeDisplayProps> = props => {
    return (
        <StyledGaugeMarkerDisplay>
            <GaugeMarker
                target={50}
                actual={props.actual}
                isShowDiff={true}
                shape={"circle"}
            />
        </StyledGaugeMarkerDisplay>
    );
}

const GaugeMarker: FC<{
    target: number,
    actual?: number,
    isShowDiff?: boolean,
    shape?: "circle" | "diamond"
}> = props => {
    const t = useTriton();
    const actual = props.actual ?? 0;
    const diff = Math.abs(actual - props.target);
    const isExceeding = actual > props.target;

    return (
        <>
            <motion.div
                style={{
                    position: "absolute",
                    width: 7,
                    aspectRatio: "1 / 1",
                    borderRadius: "50%",
                    backgroundColor: t.col("color_secondary"),
                    transform: "translateY(50%)"
                }}
                animate={{
                    bottom: `${props.target}%`
                }}
            >

            </motion.div>
            <motion.div
                style={{
                    opacity: .4,
                    position: "absolute",
                    width: 1,
                    borderLeft: `1px dashed ${t.col("color_secondary")}`,
                }}
                animate={{
                    bottom: `${isExceeding ? actual - diff : props.target - diff}%`,
                    height: `${diff}%`
                }}
            />
        </>
    );
}

export type MarkedArea = {
    // basic information
    start: number,
    end: number,

    // visual information
    withinAreaColor?: string,
    outsideAreaColor?: string,
    /**
     * Describes the border rounding of the area.
     * @default none
     */
    bevelMode?: "top" | "bottom" | "none" | "both",

    border?: boolean
}

const GaugeMarkedArea: FC<MarkedArea & {
    actual: number,
    style?: CSSProperties,
}> = props => {
    const t = useTriton();
    const { actual, start, end, bevelMode } = props;
    const isWithinArea = actual >= start && actual <= end;
    const area = Math.min(end, 100) - start;
    // Coloring
    const withinAreaColor = props.withinAreaColor ?? t.col("color_secondary")
    const outsideAreaColor = props.outsideAreaColor ?? t.col("color_secondary")
    // Bevel (border-radius)
    const isShowingTopBevel = bevelMode === "both" || bevelMode === "top";
    const isShowingBottomBevel = bevelMode === "both" || bevelMode === "bottom";
    const bevelAmountInPx = 9;
    // Border
    const isShowingBorder = props.border ?? true;

    return (
        <motion.div
            style={{
                zIndex: 5,
                width: "100%",
                position: "absolute",
                height: 5,
                left: 0,
                display: "grid",

                border: isShowingBorder ?
                    `1px solid ${isWithinArea ? withinAreaColor : outsideAreaColor}` :
                    "none",
                // borderLeft: "none",
                // borderRight: "none",

                bottom: `${(Math.min(end, 100)) - area}%`,
                overflow: "hidden",
                // top-side bevel
                borderTopLeftRadius: isShowingTopBevel ? bevelAmountInPx : 0,
                borderTopRightRadius: isShowingTopBevel ? bevelAmountInPx : 0,
                // bottom-side bevel
                borderBottomLeftRadius: isShowingBottomBevel ? bevelAmountInPx : 0,
                borderBottomRightRadius: isShowingBottomBevel ? bevelAmountInPx : 0
            }}
            initial={{
                height: `0%`,
            }}
            animate={{
                height: `${area}%`,
            }}
        >
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                opacity: .4,
                background: `
                        repeating-linear-gradient(
                          -45deg,
                          ${isWithinArea ? withinAreaColor : outsideAreaColor},
                          ${isWithinArea ? withinAreaColor : outsideAreaColor} 1px,
                          transparent 1px,
                          transparent 7.5px
                        )
                    `
            }}/>
        </motion.div>
    );
}


